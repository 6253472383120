/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
// import queryString from 'query-string';
import moment from 'moment';

import { Spin, Divider, Alert } from 'antd';
import {
  MailFilled,
  TwitterCircleFilled,
  FacebookFilled,
  InstagramFilled,
  TagsFilled,
  ClockCircleFilled,
  PushpinFilled,
} from '@ant-design/icons';

// eslint-disable-next-line no-unused-vars
import { PartnerContext, Schedule } from '../../common/types';
import { partnerContext as _partnerContext } from '../../contexts/partner/partnerContext';
import Menu from './Menu';

const Partner = (props: {
  match?: { params: { partnerId } },
  location: { search: any },
  partnerId: string,
  schedule: Schedule,
}) => {
  const partnerContext: PartnerContext = useContext(_partnerContext);
  const getPartner = partnerContext.getPartner!;
  const clearPartner = partnerContext.clearPartner!;
  const { partner } = partnerContext;
  const { partnerId, schedule } = props;

  useEffect(() => {
    if (partnerId) {
      clearPartner();
      getPartner(schedule.id, partnerId);
    }
    return () => clearPartner();
  }, [partnerId, schedule]);

  return (
    <div className="partner-container">
      {!partnerId ? <Divider style={{ margin: '1px 0 1rem 0' }} /> : null}
      {!partner ? <Spin style={{ margin: '50% 0 0 50%' }} /> : null}
      {!partnerId ? <h1 className="partner-title">{partner?.display_name}</h1> : null}
      <div>
        <div>
          {
            partner
              && partner.menu
              && partner.menu.categories
              && partner.menu.categories.length
              && (typeof partner.menuApproved === 'string')
              ? (
                <Menu
                  menu={partner?.menu}
                  schedulePartner={partner}
                  schedule={schedule}
                />
              ) : (
                <Alert message="Menu Coming Soon" style={{ textAlign: 'center' }} type="warning" />
              )
          }
        </div>
        <div>
          {partner ? (
            <div className="partner-detail">
              <div className="partner-photo">
                <img src={partner.photo} alt="" />
              </div>
              <h3 style={{ textAlign: 'center', marginTop: '1rem' }}>
                {/* {`Orders ready for pickup starting ${process.env.REACT_APP_STAGE === 'prod'
                ? '7:00 PM' : schedule.time_start_format}`} */}
                {`Orders ready for pickup starting ${moment(partner.utc_start).format('h:mm a')}`}

              </h3>
              <div>
                <ClockCircleFilled className="partner-icon" />
                {partner.food?.map((f: string, i: number) => `${f.charAt(0).toUpperCase()}${f.slice(1)}${i < partner.food!.length - 1 ? ', ' : ''}`)}
              </div>
              <div className="categories">
                <TagsFilled className="partner-icon" />
                {partner.food_category?.map((cat: string) => cat.replace(/_/gi, ' ')).join(', ')}
              </div>
              <div>
                <PushpinFilled className="partner-icon" />
                {partner.address?.substring(partner.address.indexOf(',') + 1)}
              </div>
              <div className="social-icons">
                <div>
                  <a href={partner.facebook} rel="noopener noreferrer" target="_blank">
                    <FacebookFilled className="partner-icon" />
                  </a>
                </div>
                <div>
                  <a href={partner.twitter} rel="noopener noreferrer" target="_blank">
                    <TwitterCircleFilled className="partner-icon" />
                  </a>
                </div>
                <div>
                  <a href={partner.instagram} rel="noopener noreferrer" target="_blank">
                    <InstagramFilled className="partner-icon" />
                  </a>
                </div>
                <div>
                  <a href={`mailto:${partner.email}`}>
                    <MailFilled className="partner-icon" />
                  </a>
                </div>
              </div>

              {partner.description && (
                <p id="description">
                  {new DOMParser().parseFromString(partner.description || '', 'text/html').body.textContent}
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Partner;
