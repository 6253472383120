import {
  GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS, GET_PARTNER_ERROR,
  GET_MENU_REQUEST, GET_MENU_SUCCESS, GET_MENU_ERROR, CLEAR_PARTNER,
} from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_PARTNER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        partner: action.payload,
      };
    case GET_PARTNER_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case GET_MENU_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        isFetching: false,
        menu: action.payload,
      };
    case GET_MENU_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CLEAR_PARTNER:
      return {
        ...state,
        partner: null,
        menu: null,
      };
    default:
      return state;
  }
};
