import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { scheduleContext as ScheduleContext } from './scheduleContext';
import scheduleReducer from './scheduleReducer';
// eslint-disable-next-line no-unused-vars
import { SchedulesRequestParams } from '../../common/types';
import { listSchedules } from '../../common/api-client';
import {
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_ERROR,
  SET_RADIUS,
  SET_POINT,
  SET_DATE,
} from '../../common/dispatch';

const ScheduleState = (props: { children: any }) => {
  const d = new Date();
  const initialState: any = {
    schedules: [],

    point: { lat: 38.9072, lng: -77.0369 },
    radius: 20,
    date: new Date(d.getTime() - (d.getTimezoneOffset() * 60000))
      .toISOString()
      .split('T')[0],
  };

  const [state, dispatch] = useReducer(scheduleReducer, initialState);

  const dateRef = React.useRef(state.date);

  const getSchedules = async (params: SchedulesRequestParams) => {
    dispatch({ type: GET_SCHEDULES_REQUEST });
    try {
      const result = await listSchedules({ ...params, date: dateRef.current });
      const items = result.items ? result.items : result;

      const schedules = process.env.REACT_APP_STAGE === 'prod'
        ? items.filter((i) => i.event.building !== '85571691') : items;

      dispatch({
        type: GET_SCHEDULES_SUCCESS,
        payload: schedules,
      });
    } catch (err) {
      const message = err?.response?.data?.error;
      dispatch({
        type: GET_SCHEDULES_ERROR,
        payload: message,
      });
    }
  };

  const setRadius = (radius: number) => {
    dispatch({
      type: SET_RADIUS,
      payload: radius,
    });
  };

  const setPoint = (radius: number) => {
    dispatch({
      type: SET_POINT,
      payload: radius,
    });
  };

  const setDate = (date: string) => {
    dateRef.current = date;
    dispatch({
      type: SET_DATE,
      payload: date,
    });
  };

  const { children } = props;

  return (
    <ScheduleContext.Provider
      value={{
        getSchedules,
        setRadius,
        setPoint,
        setDate,
        isFetching: state.isFetching,
        errorMessage: state.errorMessage,
        schedules: state.schedules,
        point: state.point,
        radius: state.radius,
        date: state.date,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleState;

ScheduleState.propTypes = {
  children: PropTypes.shape({}),
};

ScheduleState.defaultProps = {
  children: {},
};
