import React from 'react';

import { Modal } from 'antd';
import Checkout from '../Cart/Checkout';

const MenuItem = ({ visible, onCancel }: { visible: boolean, onCancel: any }) => {
  // const [currentStep, setCurrentStep] = useState<number>(0);

  // const nextStep = (step: number) => {
  //   setCurrentStep(step);
  // };

  const closeDialog = () => {
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      title="Sign In"
      onCancel={onCancel}
      onOk={() => { }}
      footer={null}
    >

      <Checkout
        closeDialog={closeDialog}
        // currentStep={currentStep}
        // setCurrentStep={nextStep}
        buildPaymentForm={false}
        updateCartPaymentRef={() => { }}
        nonceReceived={() => { }}
      />
    </Modal>
  );
};

export default MenuItem;
