import React from 'react';
import { Link } from 'react-router-dom';

import { Result, Button, Divider } from 'antd';

interface Props {
  orderSummary: {
    total: string,
    id: string,
    orderNumFormatted: string,
  },
  onClose(): void,
}

const Success = ({ orderSummary, onClose }: Props) => (

  <Result
    status="success"
    title="Order Placed!"
    subTitle={(
      <>
        <div>{`Order number: ${orderSummary.orderNumFormatted}`}</div>
        <div>{`Order amount: ${orderSummary.total}`}</div>
        <Divider style={{ margin: '1rem 0' }} />
        <div>A confirmation email has been sent</div>
        <div>You will recieve SMS alerts tracking your order&apos;s progress</div>
      </>
    )}
    extra={[
      <Link key="orders" to="/orders" onClick={onClose}>
        <Button key="track" block type="primary" size="large">Track Order</Button>
      </Link>,
    ]}
  />
);


export default Success;
