import { API } from 'aws-amplify';
import * as firebase from 'firebase/app';
import config from '../config';
// eslint-disable-next-line no-unused-vars
import { SchedulesRequestParams, MenuType, BuildingRequestParams } from './types';

const API_NAME = 'CurbsideRestAPI';

interface Headers {
  'X-Api-Key': string;
  Authorization?: string;
  platform: string;
}

// TODO Remove this once data from backend is consistent
// Fix nested food truck field
function fixScheduleFoodTruckData(schedules: any[]): any[] {
  return schedules.map((schedule) => {
    if (schedule.food_trucks) {
      const foodTrucks = schedule.food_trucks.map((truck) => {
        if (truck.food_truck) {
          return {
            id: truck.food_truck.id,
            name: truck.food_truck.name,
            display_name: truck.food_truck.display_name,
            photo: truck.food_truck.photo,
            photo_thumbnail: truck.food_truck.photo_thumbnail,
          };
        }
        return { ...truck };
      });
      return {
        ...schedule,
        food_trucks: foodTrucks,
      };
    }
    return { ...schedule };
  });
}

async function getHeaders(): Promise<Headers> {
  const headers: Headers = {
    'X-Api-Key': config.aws.API.apiKey,
    platform: 'web',
  };

  // currently amplify's axios wrapper
  // does not include interceptors
  // so we add the token here

  let token;

  const { currentUser } = firebase.auth();

  if (currentUser) {
    token = await currentUser.getIdToken();
  }

  if (token) {
    headers.Authorization = token;
  }
  return headers;
}

export async function listMarkets(): Promise<any> {
  return API.get(API_NAME, '/markets', { headers: await getHeaders() });
}

export async function listSchedules(queryStringParameters: SchedulesRequestParams): Promise<any> {
  const response = await API.get(API_NAME, '/schedules', {
    headers: await getHeaders(),
    queryStringParameters,
  });
  // TODO Remove this once data from backend is consistent
  return fixScheduleFoodTruckData(response);
}

export async function listBuildings(queryStringParameters: BuildingRequestParams): Promise<any> {
  return API.get(API_NAME, '/buildings', { headers: await getHeaders(), queryStringParameters });
}

export async function getPartnerById(scheduleId: string, partnerId: string): Promise<any> {
  return API.get(
    API_NAME,
    `/schedules/${scheduleId}/partners/${partnerId}`,
    { headers: await getHeaders() },
  );
}

export async function getMenuByType(partnerId: string, menuType: MenuType): Promise<any> {
  return API.get(API_NAME, `/partners/${partnerId}/menus?menuType=${menuType}`, { headers: await getHeaders() });
}

export async function getBuildingById(id: string): Promise<any> {
  const response = await API.get(API_NAME, `/buildings/${id}`, { headers: await getHeaders() });
  // TODO Remove this once data from backend is consistent
  response.schedules = fixScheduleFoodTruckData(response.schedules);
  return response;
}

export async function getBuildingScheduleById(
  buildingId: string, scheduleId: string,
): Promise<any> {
  return API.get(API_NAME, `/buildings/${buildingId}/schedules/${scheduleId}`, { headers: await getHeaders() });
}

export async function getApiMe(user, partnerId?: string): Promise<any> {
  let queryStringParameters = {};
  if (partnerId) { queryStringParameters = { partnerId }; }
  return API.get(API_NAME, '/me', { headers: await getHeaders(), queryStringParameters });
}

export async function updateApiMe(user): Promise<any> {
  return API.post(API_NAME, '/me', { body: user, headers: await getHeaders() });
}

export async function listMeOrders(): Promise<any> {
  return API.get(API_NAME, '/me/orders', { headers: await getHeaders() });
}

export async function placeOrder(order): Promise<any> {
  return API.post(API_NAME, '/me/orders', { body: order, headers: await getHeaders() });
}

export async function getCoupon(code: string): Promise<any> {
  return API.get(API_NAME, `/coupons/${code}`, { headers: await getHeaders() });
}

export async function addBuilding(buildingId: string, me): Promise<any> {
  const {
    firstName, lastName, email, phoneNumber,
  } = me;

  return API.post(API_NAME, `/me/buildings/${buildingId}`, {
    body: {
      firstName, lastName, email, phoneNumber,
    },
    headers: await getHeaders(),
  });
}

export async function removeBuilding(buildingId: string): Promise<any> {
  return API.del(API_NAME, `/me/buildings/${buildingId}`, {
    headers: await getHeaders(),
  });
}
