/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define, camelcase, react/no-array-index-key, no-unsafe-finally */
import React, {
  useContext, useState, useEffect, useRef,
} from 'react';
import moment from 'moment';
import uuidv4 from 'uuid/dist/v4';
import {
  Drawer, Descriptions, Button, Collapse, Divider,
  message, Popconfirm, Input, Form,
  //   Radio, InputNumber,
} from 'antd';
import {
  TagOutlined,
  // EditOutlined, CheckOutlined,
} from '@ant-design/icons';
import { captureException } from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { CartContext, AuthContext, OrderType } from '../../common/types';
import { cartContext as _cartContext } from '../../contexts/cart/cartContext';
import { authContext as _authContext } from '../../contexts/auth/authContext';

import Checkout from './Checkout';
import Success from './Success';
import Error from './Error';

const { Panel } = Collapse;

const Cart = ({ onReset }: { onReset(): void }) => {
  const authContext: AuthContext = useContext(_authContext);

  const getMe = authContext.getMe!;
  const setMe = authContext.setMe!;

  const {
    auth,
    me,
    step,
  } = authContext;

  const cartContext: CartContext = useContext(_cartContext);

  const setRenderPaymentForm = cartContext.setRenderPaymentForm!;
  const setPaymentMethod = cartContext.setPaymentMethod!;
  const setVisible = cartContext.setVisible!;
  const clearItems = cartContext.clearItems!;
  const submitOrder = cartContext.submitOrder!;
  const resetCart = cartContext.resetCart!;
  const removeItem = cartContext.removeItem!;
  const getPromoCode = cartContext.getPromoCode!;
  const applyPromoCode = cartContext.applyPromoCode!;

  const {
    visible,
    cart,
    schedulePartner,
    schedule,
    renderPaymentForm,
    paymentMethod,
    orderSummary,
  } = cartContext;

  const [isCheckout, setIsCheckout] = useState<string[]>([]);
  const [buildPaymentForm, setBuildPaymentForm] = useState<boolean>(false);
  const [orderProcessing, setOrderProcessing] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<any>({
    // TODO dynamic pickup times
    queueSlot: null,
  });
  const [pickupValidation, setPickupValidation] = useState<any>();
  const [parkingSpaceParts, setParkingSpaceParts] = useState<{ 1?: string, 2?: string }>({});
  const [noteValidation, setNoteValidation] = useState<any>();
  const [enteringPromoCode, setEnteringPromoCode] = useState<boolean>(false);
  const [addPromoLoading, setAddPromoLoading] = useState<boolean>(false);
  // const [editingTip, setEditingTip] = useState<boolean>(false);
  const [
    tipPercentage,
    // setTipPercentage
  ] = useState<number>(0);
  const [
    customTip,
    // setCustomTip
  ] = useState<number>();
  const [
    isCustomTip,
    // setIsCustomTip
  ] = useState<boolean>(false);

  const paymentFormRef = useRef<any>();

  const [promoForm] = Form.useForm();

  useEffect(() => {
    if (me && ((!me.customer || !me.customer.cards) || renderPaymentForm)) {
      setBuildPaymentForm(true);
    }
    // initial set default payment method
    if (me && me.customer && me.customer.cards && !paymentMethod) {
      setPaymentMethod(me.customer.cards[0].id);
    }
  }, [me, renderPaymentForm]);

  useEffect(() => {
    if (orderDetails.nonce || orderDetails.existingCardId || orderDetails.noPayment) {
      handleSubmitOrder();
    }
  }, [orderDetails]);

  const handleSubmitOrder = async () => {
    const tip = getTip();
    try {
      const order = {
        userId: me.id,
        key: cart.key || uuidv4().replace(/-/g, ''),
        orderType: orderDetails.queueSlot === OrderType.ASAP ? OrderType.ASAP : OrderType.AHEAD,
        ...orderDetails,
        items: cart.items.map((i) => {
          const modifiers = Object.keys(i.modifiers)
            .map((group) => i.modifiers[group]
              .map((m) => ({ catalog_object_id: m.id })))
            .flat();
          return {
            quantity: i.quantity ? i.quantity.toString() : '1',
            catalog_object_id: i.variation.id,
            modifiers,
          };
        }),
        partnerId: schedulePartner!.food_truck!.toString(),
        scheduleId: schedule!.id!.toString(),
        isSaveNewCard: false,
        couponCode: cart.couponCode,
        tip: (tip || 0) * 100,
      };

      if (order.orderType === OrderType.AHEAD) {
        order.queueSlotIndex = orderDetails.queueSlot;
      }

      const response = await submitOrder(order);

      if (!response) {
        setOrderProcessing(false);
        setIsCheckout([]);
        setIsError(true);
      } else {
        setOrderProcessing(false);
        setOrderDetails({});
        clearItems();
        setIsCheckout([]);
        setIsSuccess(true);

        if (me && me.customer) {
          const { customer, ...customerRemovedMe } = me;
          setMe(customerRemovedMe);
        }

        if (orderDetails.nonce) {
          // new card was added get me to refresh customer info
          // TODO need to combine auth context user & auth
          const newMe = await getMe({ xa: auth.token });
          setMe(newMe);
        }

        // reset default payment method
        if (me && me.customer && me.customer.cards) {
          // setPaymentMethod(me.customer.cards[0].id);
          setRenderPaymentForm(false);
        }
        setPaymentMethod(null);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const primaryButtonClick = async (event) => {
    if (!isCheckout.length) {
      setIsCheckout(['1']);
    } else if (step === 3 || me) {
      if (!orderDetails.queueSlot && orderDetails.queueSlot !== 0) {
        setPickupValidation('Please select a pickup time!');
      } else if (schedulePartner?.tag === 'movie' && (!parkingSpaceParts[1] || !parkingSpaceParts[2])) {
        setNoteValidation('Please select a parking space for delivery!');
        message.error('Please select a parking space for delivery!');
      } else if (orderDetails.queueSlot !== OrderType.ASAP
        && moment(schedulePartner?.orderQueueSlots[orderDetails.queueSlot].utc_start)
          .subtract(15, 'minutes').isBefore()) {
        message.error('Sorry this time slot is no longer available! Please select a new pickup time');
        // } else if ((parseFloat(cart.total) - (cart.discount || 0)) < 1) {
        // message.error('Order total must be more than $1.00!');
      } else {
        setOrderProcessing(true);
        if (parseFloat(cartContext.cart.total) <= parseFloat(cartContext.cart.discount)) {
          // order is free
          setTimeout(() => {
            setOrderDetails((prevState) => ({
              queueSlot: prevState.queueSlot,
              note: prevState.note,
              noPayment: true,
            }));
          }, 1000);
        } else if (!paymentMethod || paymentMethod === 'newCard') {
          event.preventDefault();
          try {
            const tokenResult = await paymentFormRef!.current!.tokenize();
            if (tokenResult.status === 'OK') {
              const nonce = tokenResult.token;
              setTimeout(() => {
                // because this is in a callback queueSlot
                // does not exist at the time function is declared
                setOrderDetails((prevState) => ({
                  queueSlot: prevState.queueSlot,
                  note: prevState.note,
                  cardData: tokenResult.details,
                  nonce,
                }));
              }, 1000);
            } else {
              setOrderProcessing(false);
              tokenResult.errors.forEach((error) => {
                message.error(error.message);
              });
            }
          } catch (error) {
            message.error('Sorry, something went wrong. Please refresh and retry your order.');
            setOrderProcessing(false);
            captureException(error);
          }
        } else if (typeof paymentMethod === 'string') {
          // payment method is an existing card id
          setOrderDetails({ ...orderDetails, existingCardId: paymentMethod });
        }
      }
    }
  };


  // no longer used after migrating to square payments sdk
  // https://developer.squareup.com/docs/web-payments/migrate
  const cardNonceResponseReceived = (errors, nonce, cardData) => {
    if (errors) {
      setOrderProcessing(false);
      errors.forEach((error) => {
        message.error(error.message);
      });
      return;
    }
    setTimeout(() => {
      // because this is in a callback queueSlot does not exist at the time function is declared
      setOrderDetails((prevState) => ({
        queueSlot: prevState.queueSlot,
        note: prevState.note,
        cardData,
        nonce,
      }));
    }, 1000);
  };

  const setPickupTime = (queueSlot: string) => {
    setPickupValidation('');
    setOrderDetails({ ...orderDetails, queueSlot });
  };

  const getTip = () => {
    if (isCustomTip) {
      return customTip;
    }

    const total = getTotal();
    return parseFloat((total * tipPercentage).toFixed(2));
  };

  const setNote = (index: number, note: string) => {
    const parts = parkingSpaceParts;
    parts[index] = note;
    setParkingSpaceParts(parts);
    if (parts[1] && parts[2]) {
      setNoteValidation('');
      setOrderDetails({ ...orderDetails, note: `Parking Space ${parts[1]}${parts[2]}` });
    }
  };

  const onClose = () => {
    setVisible(false);
    setIsCheckout([]);
    setPaymentMethod(null);
  };

  const afterVisibleChange = () => {
    if (!visible && cart.items.length === 0) {
      resetCart(); // For context
      onReset(); // For component
    }
    // TODO Possibly remove this
    // It was in here before this event was used for the cart reset
    // Not clear what the point of this is so I left in the event call
    document
      .getElementsByClassName('ant-drawer-mask')[0]
      .addEventListener('click', () => setVisible(false));
  };

  const getTotal = () => {
    const tot = parseFloat(cart.total);

    const taxes = schedulePartner?.taxes
      ? (cart.total * (schedulePartner?.taxes.tax_data.percentage / 100)) : 0;

    const discount = cart.discount || 0;

    const customerFee = (schedulePartner!.customerFee && ((tot + taxes - discount) > 5))
      ? (schedulePartner!.customerFee / 100) : 0;

    let total = tot + taxes - discount + customerFee;

    const tip = isCustomTip ? customTip : parseFloat((total * tipPercentage).toFixed(2));

    total += tip || 0;

    if (total < 0) {
      total = taxes;
    }

    return total;
  };

  // const getTipAmounts = () => {
  //   const total = getTotal();
  //   return [15, 18, 20, 25].map((i) => ({
  //     percent: i,
  //     amount: total * (i / 100),
  //     value: i / 100,
  //   }));
  // };

  const removeItemFromCart = (item: any, index: number) => {
    removeItem(item, index);
  };

  const handleAddPromoClick = async () => {
    setAddPromoLoading(true);
    const code = promoForm.getFieldValue('promo-code');
    const coupon = await getPromoCode(code);
    if (coupon) {
      const discount = await applyPromoCode(coupon);

      if (parseFloat(cartContext.cart.total) <= parseFloat(discount)) {
        setPaymentMethod(true);
      }

      message.success('Promo code applied');
    } else {
      message.error('Promo code not found!');
    }
    setAddPromoLoading(false);
  };

  const cartItems = () => (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
    }}
    >
      <div>
        {
          cart.items.length || isSuccess ? (
            cart.items.map((item, i) => (
              <Descriptions
                key={`${item.id}-${i}`}
                title={
                  (
                    <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        {item.quantity}
                        &emsp;
                        {item.name}
                        <br />
                        <span className="cart-item-variation">{`(${item.variation.item_variation_data.name})`}</span>
                      </span>
                      <span>
                        <span style={{ padding: '0px 15px' }}>{`$${item.price}`}</span>
                        <br />

                        <Popconfirm
                          title="Are you sure?"
                          onConfirm={() => removeItemFromCart(item, i)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="link"
                            danger
                          >
                            remove
                          </Button>
                        </Popconfirm>
                      </span>
                    </span>
                  )
                }
                column={1}
              >
                {Object.keys(item.modifiers).map((key) => (
                  <Descriptions.Item label={key} key={key} className="cart-item-modifier">
                    {item.modifiers[key].map((m, idx) => `${m.name}${idx === item.modifiers[key].length - 1 ? '' : ', '}`)}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            ))
          ) : (<p>You don&apos;t have any items in your cart.</p>)
        }
      </div>
    </div>
  );

  return (
    <>
      <Drawer
        title="Shopping Cart"
        placement="right"
        onClose={onClose}
        visible={visible}
        width={400}
        closable
        afterVisibleChange={afterVisibleChange}
        footer={
          isSuccess || isError ? (null) : (
            <div>
              {
                cart.items.length ? (
                  <Collapse
                    activeKey={isCheckout}
                    onChange={() => { }}
                    bordered={false}
                    className="checkout"
                  >
                    <Panel
                      showArrow={false}
                      className="checkout-panel"
                      header={(
                        <>
                          <Descriptions>
                            <Descriptions.Item label="Subtotal" className="cart-total">
                              {`$${(parseFloat(cart.total)).toFixed(2)}`}
                            </Descriptions.Item>
                            {
                              schedulePartner?.taxes ? (
                                <Descriptions.Item label={`Tax ${schedulePartner?.taxes.tax_data.percentage}%`} className="cart-total">
                                  {`$${(cart.total * (schedulePartner?.taxes.tax_data.percentage / 100)).toFixed(2)}`}
                                </Descriptions.Item>
                              ) : null
                            }
                            {
                              schedulePartner?.customerFee
                                && ((
                                  parseFloat(cart.total)
                                  + (schedulePartner?.taxes
                                    ? (cart.total
                                      * (schedulePartner?.taxes.tax_data.percentage / 100)) : 0)
                                  - (cart.discount || 0)
                                ) > 5)
                                ? (
                                  <Descriptions.Item label="Service Fee" className="cart-total">
                                    {`$${(schedulePartner?.customerFee / 100).toFixed(2)}`}
                                  </Descriptions.Item>
                                ) : null
                            }
                            <Descriptions.Item label="Discount" className="cart-total">
                              {cart.discount ? `- $${(cart?.discount)}` : (
                                (schedulePartner?.tag !== 'movie' && !enteringPromoCode && !cart.discount) ? (
                                  <Button
                                    type="link"
                                    style={{ padding: 0, marginTop: '-10px' }}
                                    onClick={() => {
                                      if (me) {
                                        setEnteringPromoCode(true);
                                      } else {
                                        message.error('Please sign in to add a promo code');
                                      }
                                    }}
                                  >
                                    <TagOutlined />
                                    Add Promo Code
                                  </Button>
                                ) : ''
                              )}
                            </Descriptions.Item>
                            {
                              enteringPromoCode && !cart.discount ? (
                                <Descriptions.Item className="cart-total">
                                  <Form
                                    form={promoForm}
                                    hideRequiredMark
                                  >
                                    <Form.Item
                                      name="promo-code"
                                      style={{ margin: 0 }}
                                      normalize={(value) => (value || '').toUpperCase()}
                                    >
                                      <Input
                                        placeholder="Enter promo code"
                                        style={{ textAlign: 'center' }}
                                      />
                                    </Form.Item>
                                  </Form>
                                  <Button
                                    block
                                    type="primary"
                                    onClick={() => handleAddPromoClick()}
                                    loading={addPromoLoading}
                                  >
                                    Apply Promo
                                  </Button>
                                </Descriptions.Item>
                              ) : null
                            }
                          </Descriptions>
                          {/* <div className="tip-row">
                            <span className="ant-descriptions-item-label">Tip: </span>
                            <div className="tip-options">
                              {!editingTip ? (
                                <Radio.Group
                                  defaultValue={18}
                                  style={{ textAlign: 'center' }}
                                  buttonStyle="solid"
                                >
                                  {
                                    getTipAmounts().map((i) => (
                                      <Radio.Button
                                        key={i.percent}
                                        value={i.percent}
                                        className="tip-percent"
                                        onClick={() => {
                                          setIsCustomTip(false);
                                          setTipPercentage(i.value);
                                        }}
                                      >
                                        {`${i.percent}%`}
                                      </Radio.Button>
                                    ))
                                  }
                                  <Radio.Button
                                    key="Edit"
                                    value="Edit"
                                    checked={isCustomTip}
                                    onClick={() => {
                                      setEditingTip(true);
                                    }}
                                    className="edit-tip-button"
                                  >
                                    <EditOutlined />
                                  </Radio.Button>
                                </Radio.Group>
                              ) : (
                                <div className="edit-tip-input">
                                  <InputNumber
                                    defaultValue={getTip()}
                                    min={0}
                                    max={100}
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                                    onChange={(value) => { setCustomTip(value || 0); }}
                                    style={{ marginBottom: '12px', width: '90px' }}
                                  />
                                  <Button
                                    type="primary"
                                    className="save-edit-tip"
                                    onClick={() => {
                                      if (!customTip && customTip !== 0) {
                                        const tip = getTip();
                                        setCustomTip(tip);
                                      }

                                      setEditingTip(false);
                                      setIsCustomTip(true);
                                    }}
                                  >
                                    <CheckOutlined />
                                  </Button>
                                </div>
                              )}
                            </div>
                            <span className="ant-descriptions-item-content tip-display">
                              {`$${getTip()}`}
                            </span>
                          </div> */}
                          <Descriptions
                            title={
                              (
                                <span className="cart-total">
                                  <span>Total</span>
                                  <span>{`$${getTotal().toFixed(2)}`}</span>
                                </span>
                              )
                            }
                            column={1}
                          />
                        </>
                      )}
                      key="1"
                    >

                      <Checkout
                        isCart
                        buildPaymentForm={buildPaymentForm}
                        schedule={schedule}
                        setPickupTime={setPickupTime}
                        setNote={setNote}
                        pickupValidation={pickupValidation}
                        noteValidation={noteValidation}
                        schedulePartner={schedulePartner}
                        paymentFormRef={paymentFormRef}
                        updateCartPaymentRef={() => { }}
                        nonceReceived={cardNonceResponseReceived}
                      />

                    </Panel>
                  </Collapse>
                ) : null
              }
              {isCheckout.length || isSuccess || isError ? (null) : <Divider style={{ margin: '0 0 1rem 0' }} />}
              {
                isCheckout.length > 0 && !me ? (null)
                  : (
                    <Button
                      type="primary"
                      size="large"
                      block
                      style={{ marginBottom: '1rem' }}
                      loading={orderProcessing}
                      disabled={!cart.items.length || (isCheckout.length > 0 && step !== 3 && !me)}
                      onClick={(e) => primaryButtonClick(e)}
                    >
                      {isCheckout.length > 0 ? 'Place Order' : 'Checkout'}
                    </Button>
                  )
              }
              {!isCheckout.length
                ? (<Button block size="large" onClick={onClose}>Keep Shopping</Button>) : (null)}
            </div>
          )
        }
      >
        {(isSuccess && orderSummary) ? (
          <Success orderSummary={orderSummary} onClose={onClose} />
        ) : null}

        {
          (isError) ? (
            <Error />
          ) : null
        }

        {!isError && cartItems()}

      </Drawer>
    </>
  );
};

export default Cart;
