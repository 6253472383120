import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { partnerContext as PartnerContext } from './partnerContext';
import partnerReducer from './partnerReducer';
import { getPartnerById, getMenuByType } from '../../common/api-client';
import {
  GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS, GET_PARTNER_ERROR,
  GET_MENU_REQUEST, GET_MENU_SUCCESS, GET_MENU_ERROR, CLEAR_PARTNER,
} from '../../common/dispatch';

const PartnerState = (props: { children: any }) => {
  const initialState: any = {
    partner: null,
    menu: null,
  };

  const [state, dispatch] = useReducer(partnerReducer, initialState);

  const getPartner = async (scheduleId: string, partnerId: string) => {
    dispatch({ type: GET_PARTNER_REQUEST });
    try {
      const partner = await getPartnerById(scheduleId, partnerId);
      dispatch({ type: GET_PARTNER_SUCCESS, payload: partner });
    } catch (err) {
      dispatch({ type: GET_PARTNER_ERROR });
    }
  };

  const getMenu = async (partnerId: string, menuType) => {
    dispatch({ type: GET_MENU_REQUEST });
    try {
      const result = await getMenuByType(partnerId, menuType);

      const menu = {
        ...result,
        categories: result.categories.reverse(),
      };

      dispatch({
        type: GET_MENU_SUCCESS,
        payload: menu,
      });
    } catch (err) {
      dispatch({
        type: GET_MENU_ERROR,
      });
    }
  };

  const clearPartner = async () => {
    dispatch({ type: CLEAR_PARTNER });
  };

  const { children } = props;

  return (
    <PartnerContext.Provider
      value={{
        getPartner,
        getMenu,
        clearPartner,
        isFetching: state.isFetching,
        partner: state.partner,
        menu: state.menu,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerState;

PartnerState.propTypes = {
  children: PropTypes.shape({}),
};

PartnerState.defaultProps = {
  children: {},
};
