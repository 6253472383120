import React, {
  useEffect, useContext, useState,
} from 'react';
import {
  Carousel, Button, Input, DatePicker,
} from 'antd';
import { UnorderedListOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { RouteChildrenProps } from 'react-router-dom';

import google from '../../common/google';
import Map from './Map';
import List from './List';
// eslint-disable-next-line no-unused-vars
import { ScheduleContext, PartnerContext } from '../../common/types';
import { scheduleContext as _scheduleContext } from '../../contexts/schedule/scheduleContext';
import { partnerContext as _partnerContext } from '../../contexts/partner/partnerContext';


function Schedules({ history }: RouteChildrenProps) {
  const scheduleContext: ScheduleContext = useContext(_scheduleContext);
  const partnerContext: PartnerContext = useContext(_partnerContext);

  const getSchedules = scheduleContext.getSchedules!;
  const setRadius = scheduleContext.setRadius!;
  const setPoint = scheduleContext.setPoint!;
  const setDate = scheduleContext.setDate!;
  const {
    point,
    radius,
    schedules,
    date,
  } = scheduleContext;
  const clearPartner = partnerContext.clearPartner!;
  const [hoveredScheduleId, setHoveredScheduleId] = useState('');
  const [clickedScheduleId, setClickedScheduleId] = useState('');
  const [activeListItem, setActiveListItem] = useState<any>();
  const [carouselRef, setCarouselRef] = useState<any>();
  const isMobile = window.innerWidth < 767;
  const [scheduleView, setScheduleView] = useState<any>(isMobile ? 2 : 1);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [map, setMap] = useState<any>();
  const [circle, setCircle] = useState<any>();


  const [selectedPartnerId, setSelectedPartnerId] = useState<string>('');

  useEffect(() => {
    getSchedules({ radius, point: `${point!.lat},${point!.lng}` });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (schedules!.length && schedules![0].food_trucks && schedules![0].food_trucks.length) {
      setSelectedPartnerId(schedules![0].food_trucks[0].id);
      setActiveListItem(schedules![0].id);
    }
  }, [schedules]);

  const scheduleHover = (scheduleId: string, hover: boolean) => {
    if (!isMobile) {
      setHoveredScheduleId(hover ? scheduleId : '');
    }
  };

  const scheduleClick = (schedule) => {
    if (!schedule) {
      return;
    }

    if (schedule.food_trucks && schedule.food_trucks.length) {
      if (schedule.id === activeListItem) {
        history.push({
          pathname: `buildings/${schedule.event.building}`,
          search: `?schedule=${schedule.id}`,
        });
      } else {
        setSelectedPartnerId(schedule.food_trucks[0].id);
        setActiveListItem(schedule.id);
        if (!isMobile) {
          setClickedScheduleId(schedule.id);
        }
      }
    }
  };

  const updateSchedules = (params: any) => {
    getSchedules(params);
  };

  const setSelectedPartner = (id: string) => {
    setSelectedPartnerId(id);
  };

  const searchSchedules = () => {
    clearPartner();
    circle.setRadius(radius! * 1500);
    const b = circle.getBounds();
    const latlngList = [
      new google.maps.LatLng(
        b[Object.keys(b)[0]][Object.keys(b[Object.keys(b)[0]])[1]],
        b[Object.keys(b)[1]][Object.keys(b[Object.keys(b)[1]])[1]],
      ),
      new google.maps.LatLng(
        b[Object.keys(b)[0]][Object.keys(b[Object.keys(b)[0]])[0]],
        b[Object.keys(b)[1]][Object.keys(b[Object.keys(b)[1]])[0]],
      ),
    ];
    const bounds = new google.maps.LatLngBounds();
    latlngList.forEach((n) => {
      bounds.extend(n);
    });
    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
    getSchedules({ radius, point: `${point!.lat},${point!.lng}` });
  };

  const findPlace = async (query: string) => {
    const service = new google.maps.places.PlacesService(map);

    const request = { query, fields: ['geometry', 'formatted_address'] };

    service.findPlaceFromQuery(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        setSearchQuery(results[0].formatted_address);
        map.setCenter(results[0].geometry.location);
      }
    });
  };

  const updateRadius = (r: number) => {
    if (r) { setRadius(r); }
  };


  const handleApiLoaded = (_map, maps, _circle) => {
    setMap(_map);
    setCircle(_circle);

    const autocomplete = new maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        types: ['address'],
        componentRestrictions: { country: 'us' },
      },
    );

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        setSearchQuery(place.formatted_address);
        const { location } = place.geometry;
        // _map.panTo(location);
        // _map.setZoom(11);
        // _circle.setCenter(location);
        const lat = location.lat();
        const lng = location.lng();
        setPoint({ lat, lng });
      } else {
        const autocom: any = document.getElementById('autocomplete');
        autocom.placeholder = 'Enter a city';
      }
    });
  };


  return (
    <div className="schedule-container">
      <div id="search-controls">
        <Input.Group compact>
          <Input
            id="autocomplete"
            placeholder="Enter your location for next meal"
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') { findPlace(searchQuery || ''); }
            }}
          />
          <span style={{ display: 'flex' }}>
            <DatePicker
              className="search-date"
              allowClear={false}
              defaultValue={moment(date)}
              onChange={(event) => {
                const val = moment(event!).format('YYYY-MM-DD');
                setDate(val);
              }}
            />
            <Input
              id="radius-input"
              type="text"
              defaultValue={radius}
              addonAfter="mi"
              onChange={(e) => { updateRadius(parseInt(e.target.value, 10)); }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') { searchSchedules(); }
              }}
            />
          </span>
        </Input.Group>
        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => searchSchedules()}
        >
          Search
        </Button>
      </div>

      {isMobile ? null : (
        <div className="schedule-list">
          <List
            scheduleHover={scheduleHover}
            hoveredScheduleId={hoveredScheduleId}
            scheduleClick={scheduleClick}
            setSelectedPartner={setSelectedPartner}
            selectedPartnerId={selectedPartnerId}
            activeKey={activeListItem}
          />
        </div>
      )}

      <div style={{ width: isMobile ? '100vw' : '66vw' }}>
        <Carousel
          effect="fade"
          ref={(carousel) => setCarouselRef(carousel)}
          dots={false}
          style={{ backgroundColor: '#fafafa' }}
        >
          {
            isMobile && (
              <div className="schedule-list">
                <List
                  scheduleHover={scheduleHover}
                  hoveredScheduleId={hoveredScheduleId}
                  scheduleClick={scheduleClick}
                  setSelectedPartner={() => { }}
                  selectedPartnerId=""
                  activeKey={activeListItem}
                />
              </div>
            )
          }
          <div className="schedule-map">
            <Map
              point={point!}
              radius={radius!}
              schedules={schedules!}
              getSchedules={updateSchedules}
              setPoint={setPoint}
              scheduleHover={scheduleHover}
              scheduleClick={scheduleClick}
              hoveredScheduleId={hoveredScheduleId}
              clickedScheduleId={clickedScheduleId}
              parentHandleApiLoaded={handleApiLoaded}
            />
          </div>
          {
            !isMobile && (
              <div className="schedule-list">
                <List
                  scheduleHover={scheduleHover}
                  hoveredScheduleId={hoveredScheduleId}
                  scheduleClick={scheduleClick}
                  setSelectedPartner={() => { }}
                  selectedPartnerId=""
                  activeKey={activeListItem}
                />
              </div>
            )
          }
        </Carousel>
      </div>
      {isMobile ? (
        <Button
          type="primary"
          className="schedule-view-button"
          shape="circle"
          icon={
            scheduleView === 2
              ? (
                <img
                  src={`${process.env.PUBLIC_URL}/assets/map.png`}
                  alt="map-view"
                  id="map-view-icon"
                />
              )
              : <UnorderedListOutlined />
          }
          size="large"
          onClick={() => {
            const view = scheduleView === 1 ? 2 : 1;
            carouselRef.goTo(view);
            setScheduleView(view);
          }}
        />
      ) : null}
    </div>
  );
}

export default Schedules;
