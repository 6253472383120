import React, { useState } from 'react';

import { Result, Button } from 'antd';

const Error = () => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  return (

    <Result
      status="error"
      title="Order Failed"
      subTitle="Sorry, something went wrong. Please refresh and retry your order."
      extra={[
        <Button
          type="primary"
          size="large"
          key="console"
          loading={isRefreshing}
          onClick={() => { setIsRefreshing(true); window.location.reload(); }}
        >
          Refresh
        </Button>,
      ]}
    />
  );
};


export default Error;
