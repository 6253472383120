/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import {
  Tabs, Divider, Spin, Alert,
} from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
// eslint-disable-next-line no-unused-vars
import { RouteChildrenProps } from 'react-router-dom';

import moment from 'moment';
// eslint-disable-next-line no-unused-vars
import { BuildingContext, PartnerContext } from '../../common/types';
import { buildingContext as _buildingContext } from '../../contexts/building/buildingContext';
import { partnerContext as _partnerContext } from '../../contexts/partner/partnerContext';

import Partner from '../Partners/Partner';

const Building = ({ match, location, history }: {
  match?: { params: { buildingId, scheduleId?} },
} & RouteChildrenProps) => {
  const buildingContext: BuildingContext = useContext(_buildingContext);
  const partnerContext: PartnerContext = useContext(_partnerContext);
  const getBuilding = buildingContext.getBuilding!;
  const getBuildingSchedule = buildingContext.getBuildingSchedule!;
  const clearPartner = partnerContext.clearPartner!;
  const clearBuilding = buildingContext.clearBuilding!;
  const { building, isFetching } = buildingContext;
  const [partnerId, setPartnerId] = useState('');

  const isMobile = window.innerWidth < 767;

  const urlParams = new URLSearchParams(location.search);
  let defaultScheduleTab = urlParams.get('schedule') || '';
  const defaultPartnerTab = urlParams.get('partner') || '';
  if (defaultScheduleTab === '' && building && building.schedules.length) {
    const now = moment();
    for (let i = 0; i < building.schedules.length; i += 1) {
      if (moment(building.schedules[i].utc_end).isSameOrAfter(now)) {
        defaultScheduleTab = building.schedules[i].id;
        break;
      }
    }
  }

  const { TabPane } = Tabs;

  useEffect(() => {
    if (match && match.params.buildingId) {
      const { buildingId, scheduleId } = match.params;
      if (scheduleId) {
        getBuildingSchedule(buildingId, scheduleId);
      } else {
        getBuilding(buildingId);
      }
    }
    return () => clearBuilding();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="building-container">
      {isFetching ? <Spin className="building-loading" />
        : (
          <>
            <Divider style={{ margin: '0 0 1rem 0' }} />
            <h1>{building?.name}</h1>
            {building && building.schedules.length
              ? (
                <Tabs
                  onChange={(id: string) => {
                    clearPartner();
                    const newSchedule = building.schedules.find((el) => el.id.toString() === id);
                    if (newSchedule.food_trucks && newSchedule.food_trucks.length) {
                      const newPartnerId = newSchedule.food_trucks[0].id;
                      setPartnerId(newPartnerId);
                    }
                    urlParams.set('schedule', id);
                    history.replace(`${location.pathname}?${urlParams.toString()}`);
                  }}
                  destroyInactiveTabPane
                  defaultActiveKey={`${defaultScheduleTab}`}
                >
                  {
                    building?.schedules.map((schedule) => (
                      <TabPane tab={(`${moment(schedule.date).format('dddd')}   ${moment(schedule.date).format('M/DD/YY')} ${moment(schedule.utc_start).format('h:mm a')} - ${moment(schedule.utc_end).format('h:mm a')}`)} key={`${schedule.id}`} className="schedule-truck-tabs">
                        {schedule.food_trucks && schedule.food_trucks.length
                          ? (
                            <>
                              {
                                isMobile && (
                                  <div>
                                    <Alert
                                      message={(
                                        <div style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          margin: '0px -5px',
                                          alignItems: 'center',
                                        }}
                                        >
                                          <ArrowDownOutlined />
                                          <span>Use Arrows To Select Food Truck</span>
                                          <ArrowDownOutlined />
                                        </div>
                                      )}
                                      type="success"
                                    />
                                  </div>
                                )
                              }
                              <Tabs
                                onChange={(id) => {
                                  setPartnerId(id);
                                  urlParams.set('partner', id);
                                  history.replace(`${location.pathname}?${urlParams.toString()}`);
                                }}
                                defaultActiveKey={`${defaultPartnerTab}`}
                              >
                                {
                                  schedule.food_trucks.map((truck) => (
                                    <TabPane tab={truck.name} key={truck.id}>
                                      <Partner
                                        schedule={schedule}
                                        partnerId={partnerId || truck.id}
                                        location={{ search: '' }}
                                      />
                                    </TabPane>
                                  ))
                                }
                              </Tabs>
                            </>
                          ) : 'No food trucks assigned to this schedule'}
                      </TabPane>
                    ))
                  }
                </Tabs>
              ) : 'No schedules for this building'}
          </>
        )}
    </div>
  );
};

export default Building;
