// eslint-disable-next-line no-unused-vars
import React from 'react';
import uuidv4 from 'uuid/dist/v4';

import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  SET_CART_VISIBLE,
  CLEAR_CART_ITEMS,
  SET_CART_SCHEDULE_PARTNER,
  SET_CART_SCHEDULE,
  PLACE_ORDER,
  RENDER_PAYMENT_FORM,
  SET_PAYMENT_METHOD,
  RESET_CART,
  APPLY_PROMO,
} from '../../common/dispatch';

export const CART_STORAGE_KEY = 'cart';

export type CartState = any;

export interface CartAction {
  type: string,
  payload?: any,
}

type CartReducer = React.Reducer<CartState, CartAction>;

const reduce: CartReducer = (state, action) => {
  let items;
  switch (action.type) {
    case ADD_CART_ITEM:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [
            ...state.cart.items,
            action.payload,
          ],
          total: (parseFloat(state.cart.total) + parseFloat(action.payload.price)).toFixed(2),
          key: state.cart.key || uuidv4().replace(/-/g, ''),
        },
      };
    case REMOVE_CART_ITEM:
      items = state.cart.items;
      items.splice(action.payload.index, 1);
      return {
        ...state,
        cart: {
          ...state.cart,
          items,
          total: (parseFloat(state.cart.total) - parseFloat(action.payload.price)).toFixed(2),
          key: items.length ? state.cart.key : null,
        },
      };
    case CLEAR_CART_ITEMS:
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [],
          total: 0,
          key: null,
        },
      };
    case SET_CART_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    case SET_CART_SCHEDULE_PARTNER:
      return {
        ...state,
        schedulePartner: action.payload,
      };
    case SET_CART_SCHEDULE:
      return {
        ...state,
        schedule: action.payload,
      };
    case PLACE_ORDER:
      return {
        ...state,
        orderSummary: action.payload,
      };
    case RENDER_PAYMENT_FORM:
      return {
        ...state,
        renderPaymentForm: action.payload,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case RESET_CART:
      return {
        cart: {
          items: [],
          total: 0,
          discount: 0,
          key: null,
        },
        visible: false,
      };
    case APPLY_PROMO:
      return {
        ...state,
        cart: {
          ...state.cart,
          discount: parseFloat(action.payload.discount).toFixed(2),
          couponCode: action.payload.couponCode,
        },
      };
    default:
      return state;
  }
};

const saveSideEffect = (wrapped: CartReducer): CartReducer => (state, action) => {
  const newState = wrapped(state, action);
  const newStateStr = JSON.stringify(newState);
  window.localStorage.setItem(CART_STORAGE_KEY, newStateStr);
  return newState;
};

export default saveSideEffect(reduce);
