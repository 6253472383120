export const SET_AUTH = 'SET_AUTH';
export const SET_ME = 'SET_ME';
export const GET_SCHEDULES_REQUEST = 'GET_SCHEDULES_REQUEST';
export const GET_SCHEDULES_ERROR = 'GET_SCHEDULES_ERROR';
export const GET_SCHEDULES_SUCCESS = 'GET_SCHEDULES_SUCCESS';
export const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
export const GET_PARTNER_ERROR = 'GET_PARTNER_ERROR';
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_ERROR = 'GET_MENU_ERROR';
export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS';
export const CLEAR_PARTNER = 'CLEAR_PARTNER';
export const SET_RADIUS = 'SET_RADIUS';
export const SET_POINT = 'SET_POINT';
export const SET_DATE = 'SET_DATE';
export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM';
export const CLEAR_CART_ITEMS = 'CLEAR_CART_ITEMS';
export const SET_CART_VISIBLE = 'SET_CART_VISIBLE';
export const SET_CART_SCHEDULE_PARTNER = 'SET_CART_SCHEDULE_PARTNER';
export const SET_CART_SCHEDULE = 'SET_CART_SCHEDULE';
export const GET_BUILDINGS_REQUEST = 'GET_BUILDINGS_REQUEST';
export const GET_BUILDINGS_ERROR = 'GET_BUILDINGS_ERROR';
export const GET_BUILDINGS_SUCCESS = 'GET_BUILDINGS_SUCCESS';
export const GET_BUILDING_REQUEST = 'GET_BUILDING_REQUEST';
export const GET_BUILDING_ERROR = 'GET_BUILDING_ERROR';
export const GET_BUILDING_SUCCESS = 'GET_BUILDING_SUCCESS';
export const CLEAR_BUILDING = 'CLEAR_BUILDING';
export const SET_MY_BUILDINGS = 'SET_MY_BUILDINGS';
export const ADD_TO_MY_BUILDINGS = 'ADD_TO_MY_BUILDINGS';
export const REMOVE_FROM_MY_BUILDINGS = 'REMOVE_FROM_MY_BUILDINGS';
export const PLACE_ORDER = 'PLACE_ORDER';
export const RENDER_PAYMENT_FORM = 'RENDER_PAYMENT_FORM';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const RESET_CART = 'RESET_CART';
export const APPLY_PROMO = 'APPLY_PROMO';
