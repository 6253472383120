import {
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_ERROR,
  SET_RADIUS,
  SET_POINT,
  SET_DATE,
} from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_SCHEDULES_REQUEST:
      return {
        ...state,
        schedules: [],
        isFetching: true,
        errorMessage: '',
      };
    case GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        schedules: action.payload,
      };
    case GET_SCHEDULES_ERROR:
      return {
        ...state,
        isFetching: false,
        schedules: [],
        errorMessage: action.payload,
      };
    case SET_RADIUS:
      return {
        ...state,
        radius: action.payload,
      };
    case SET_POINT:
      return {
        ...state,
        point: action.payload,
      };
    case SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    default:
      return state;
  }
};
