import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';

const SENTRY_KEY = 'a361f36bee274b538d55f8d52ced71b8';
const SENTRY_ORG = 'o419715';
const SENTRY_PROJECT = '5337794';

if (process.env.NODE_ENV !== 'test' || window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: `https://${SENTRY_KEY}@${SENTRY_ORG}.ingest.sentry.io/${SENTRY_PROJECT}`,
    environment: process.env.REACT_APP_STAGE,
  });
}
ReactDOM.render(<App />, document.getElementById('root'));
