/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key, no-use-before-define */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import {
  Form, Steps, Button, Divider, Descriptions, message, Input, Radio, Select, Checkbox,
} from 'antd';
import { captureException } from '@sentry/react';
import * as firebase from 'firebase/app';

import moment from 'moment';

import MaskedInput from 'react-text-mask';
import {
  CreditCardOutlined,
  MessageOutlined,
  MobileOutlined,
  IdcardOutlined,
} from '@ant-design/icons';

import {
  // eslint-disable-next-line no-unused-vars
  AuthContext, CartContext, Schedule, SchedulePartner, OrderQueueSlot, OrderType,
} from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import { cartContext as _cartContext } from '../../contexts/cart/cartContext';

import Payment from './Payment';

import 'firebase/auth';

declare global {
  interface Window {
    verification: any,
    grecaptcha: any,
  }
}

const { Step } = Steps;
const { Option } = Select;

const Checkout = (
  {
    buildPaymentForm,
    closeDialog,
    isCart,
    setPickupTime,
    pickupValidation,
    setNote,
    noteValidation,
    schedulePartner,
    paymentFormRef,
    updateCartPaymentRef,
    nonceReceived,
  }: {
    buildPaymentForm: boolean,
    isCart?: boolean,
    closeDialog?: Function,
    schedule?: Schedule,
    setPickupTime?: Function,
    pickupValidation?: any,
    setNote?: Function,
    noteValidation?: any,
    schedulePartner?: SchedulePartner,
    paymentFormRef?: any,
    updateCartPaymentRef: Function,
    nonceReceived: Function,
  },
) => {
  const [form] = Form.useForm();
  const [smsValidation, setSmsValidation] = useState<any>({});
  const [smsConsent, setSMSConsent] = useState<boolean>(false);
  const recaptchaVerifier = useRef<any>();

  const [loading, setLoading] = useState<{
    phone?: any; code?: boolean; account?: boolean,
  }>({});

  const authContext: AuthContext = useContext(_authContext);
  const cartContext: CartContext = useContext(_cartContext);

  const setRenderPaymentForm = cartContext.setRenderPaymentForm!;
  const setPaymentMethod = cartContext.setPaymentMethod!;

  const { paymentMethod } = cartContext;
  const { me, step } = authContext;
  const signInUser = authContext.signInUser!;
  const setMe = authContext.setMe!;
  const setStep = authContext.setStep!;
  const createMe = authContext.createMe!;
  const getMe = authContext.getMe!;

  const [queueSlots, setPickupTimes] = useState<OrderQueueSlot[]>([]);

  const mutationObservers = useRef<{
    body?: MutationObserver,
    captcha?: MutationObserver,
  }>({});

  useEffect(() => {
    updateCartPaymentRef();
  }, [paymentFormRef]);

  useEffect(() => {
    const recaptchaExpired = () => {
      message.error('reCaptcha expired. Try again.');
      setLoading({ ...loading, phone: false });
    };

    // Handles network connectivity errors
    const recaptchaError = () => {
      message.error('reCaptcha failed. Try again.');
      setLoading({ ...loading, phone: false });
    };

    if (!me && step === 0) { // Submit phone, send code step is open
      recaptchaVerifier.current = new firebase.auth.RecaptchaVerifier('submit-phone-button', {
        size: 'invisible',
        // callback: () => {},
        'expired-callback': recaptchaExpired,
        'error-callback': recaptchaError,
      });
    }

    return () => {
      if (recaptchaVerifier.current) {
        recaptchaVerifier.current.clear();
        recaptchaVerifier.current = null;
      }
    };
  }, [me, step]);

  const watchCaptchaChange = (parentDiv: HTMLElement) => {
    // const stamp = Date.now();
    const observer = new MutationObserver(() => {
      if (parentDiv.style.visibility === 'hidden') {
        // console.debug(`challenge hidden ${stamp}`);
        window.grecaptcha.execute();
      } else {
        // console.debug(`challenge shown ${stamp}`);
      }
    });
    observer.observe(parentDiv, { attributes: true, attributeFilter: ['style'] });
    mutationObservers.current.captcha = observer;
  };

  const watchCaptchaMount = () => {
    const observer = new MutationObserver((records) => {
      records.forEach((record) => {
        if (record.addedNodes.length) {
          const parentDiv = record.addedNodes[0] as HTMLDivElement;
          const iframe = parentDiv.querySelector('iframe[src*="google.com/recaptcha/api2/bframe"]');
          if (iframe) {
            // console.debug('captcha mounted');
            observer.disconnect();
            mutationObservers.current.body = undefined;
            watchCaptchaChange(parentDiv);
          }
        }
      });
    });
    observer.observe(document.body, { childList: true });
    mutationObservers.current.body = observer;
  };

  const watchCaptcha = () => {
    cleanupCaptchaWatchers();
    const iframe = document.querySelector('iframe[src*="google.com/recaptcha/api2/bframe"]');
    if (iframe) { // If captcha already mounted, skip first observer (which won't fire)
      // console.debug('catpcha already mounting, just watching changes');
      const parentDiv = iframe.parentElement!.parentElement!;
      watchCaptchaChange(parentDiv);
    } else {
      watchCaptchaMount();
    }
  };

  const cleanupCaptchaWatchers = () => {
    const bodyObserver = mutationObservers.current.body;
    if (bodyObserver) {
      bodyObserver.disconnect();
      mutationObservers.current.body = undefined;
    }
    const captchaObserver = mutationObservers.current.captcha;
    if (captchaObserver) {
      captchaObserver.disconnect();
      mutationObservers.current.captcha = undefined;
    }
  };

  useEffect(() => {
    setPickupTimes(schedulePartner?.tag !== 'movie' && schedulePartner ? schedulePartner.orderQueueSlots : []);
  }, []);

  const onSMSConsentChange = () => {
    setSMSConsent(!smsConsent);
  };

  const steps = [
    ...!me ? [{
      title: 'Get Code',
      icon: (<MobileOutlined onClick={() => { form.resetFields(); setStep(0); }} />),
      content: (
        <div className="checkout-input-row">
          <Form.Item
            name="phone"
            className="checkout-input"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: 'Please input your phone number',
              },
              {
                len: 14,
                message: 'Must include area code',
              },
            ]}
          >
            <MaskedInput
              type="tel"
              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              className="ant-input"
              // autoFocus
              placeholder="Enter phone number"
              guide={false}
              onBlur={() => { }}
            />
          </Form.Item>
          <Checkbox onChange={onSMSConsentChange} style={{ margin: '10px 0px' }}>
            I agree and consent to receive automated text messages regarding
            my order status and events at the phone number provided.
            Terms and Privacy Policy can be found at&nbsp;
            <a href="curbsidekitchen.com/terms-of-service/">
              curbsidekitchen.com/terms-of-service/
            </a>
            &nbsp;and&nbsp;
            <a href="curbsidekitchen.com/privacy-policy/">
              curbsidekitchen.com/privacy-policy/
            </a>
            . Text and Data rates may apply. Reply STOP to cancel.
          </Checkbox>
          <Button
            // Key needed so recaptcha listener isn't fired when react reuses button element
            key="submit-phone-button"
            id="submit-phone-button"
            block
            size="large"
            type="primary"
            loading={loading.phone}
            onClick={() => submitPhoneNumber()}
            disabled={!smsConsent}
          >
            Send Code
            {loading.phone}
          </Button>
        </div>
      ),
    },
    {
      icon: (<MessageOutlined />),
      title: 'Confirm Code',
      content: (
        <div className="checkout-input-row">
          <Form.Item
            name="code"
            className="checkout-input"
            validateTrigger="onSubmit"
            validateStatus={smsValidation !== {} ? smsValidation.status : undefined}
            help={smsValidation !== {} ? smsValidation.help : undefined}
            rules={[
              {
                required: true,
                message: 'Please input your confirmation code',
              },
              {
                len: 6,
                message: 'Code is 6 digits long',
              },
            ]}
          >
            <MaskedInput
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
              className="ant-input"
              autoFocus
              placeholder="Enter confirmation code"
              guide={false}
              onBlur={() => { }}
              onChange={() => { }}
            />
          </Form.Item>
          <Button
            // Key needed so recaptcha listener isn't fired when react reuses button element
            key="submit-code-button"
            type="primary"
            size="large"
            block
            onClick={() => submitConfirmCode()}
            loading={loading.code}
          >
            Submit
          </Button>
        </div>
      ),
    },
    {
      icon: (<IdcardOutlined />),
      title: 'Account Info',
      content: (
        <div className="checkout-input-row">
          <div style={{ display: 'flex' }}>
            <Form.Item
              name="first-name"
              className="checkout-input"
              style={{ marginRight: '0.5rem' }}
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="last-name"
              className="checkout-input"
              style={{ marginLeft: '0.5rem' }}
              validateTrigger="onSubmit"
              rules={[
                {
                  required: true,
                  message: 'Required',
                },
              ]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            className="checkout-input"
            validateTrigger="onSubmit"
            rules={[
              {
                required: true,
                message: 'Required',
              },
              {
                type: 'email',
                message: 'Not a valid email',
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Button
            type="primary"
            size="large"
            block
            onClick={() => submitAccountInfo()}
            loading={loading.account}
          >
            Submit
          </Button>
        </div>
      ),
    }] : [],
    ...isCart ? [{
      icon: (me ? <span /> : <CreditCardOutlined />),
      title: 'Confirm Payment',
      content: (
        <div>
          {
            me && me.customer && me.customer.cards
              ? (
                <Radio.Group
                  size="large"
                  onChange={(e) => {
                    if (e.target.value === 'newCard') {
                      setRenderPaymentForm(true);
                    }
                    setPaymentMethod(e.target.value);
                  }}
                  style={{ marginLeft: '15px' }}
                  defaultValue={me.customer.cards[0].id}
                >
                  {
                    me.customer.cards.map((card) => (
                      <Radio className="vertical-radio" value={card.id} key={card.id}>
                        {`${card.card_brand}  •••• ${card.last_4}`}
                      </Radio>
                    ))
                  }
                  <Radio className="vertical-radio" value="newCard">
                    Add New Card
                  </Radio>
                </Radio.Group>
              ) : null
          }

          <div style={{ display: !paymentMethod || paymentMethod === 'newCard' ? 'block' : 'none' }}>
            <Payment
              buildPaymentForm={buildPaymentForm}
              paymentFormRef={paymentFormRef}
              nonceReceived={nonceReceived}
              locationId={schedulePartner?.merchant.locationId}
            />
          </div>
        </div>
      ),
    }] : [],
  ];

  const submitPhoneNumber = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return;
    }

    setLoading({ ...loading, phone: true });
    watchCaptcha();

    const phoneNumber = form.getFieldValue('phone');
    let signInResponse;
    try {
      signInResponse = await firebase.auth()
        .signInWithPhoneNumber(`+1${phoneNumber.replace(/\D/g, '')}`, recaptchaVerifier.current);
    } catch (authError) {
      switch (authError.code) {
        case 'auth/captcha-check-failed':
          message.error('reCaptcha failed. Try again.');
          break;
        case 'auth/invalid-phone-number':
          message.error('Invalid phone number.');
          break;
        default:
          message.error(authError.message);
          break;
      }
      window.grecaptcha.reset();
      return;
    } finally {
      setLoading({ ...loading, phone: false });
      cleanupCaptchaWatchers();
    }

    window.verification = signInResponse.verificationId;
    message.success(`Confirmation code sent to ${phoneNumber}`);
    setStep(1);
  };

  const submitConfirmCode = async () => {
    setSmsValidation({
      status: undefined,
      help: undefined,
    });

    try {
      await form.validateFields();
    } catch (validationError) {
      return;
    }

    setLoading({ ...loading, code: true });

    // firebase signin
    const code = form.getFieldValue('code');
    const credential = firebase.auth.PhoneAuthProvider.credential(window.verification, code);
    let signInResponse;
    try {
      signInResponse = await signInUser(credential);
    } catch (authError) {
      setSmsValidation({
        status: 'error',
        help: (
          <span>
            <span>Invalid code.</span>
            <Button
              type="link"
              style={{ paddingLeft: '7px' }}
              onClick={submitPhoneNumber}
            >
              Send again?
            </Button>
          </span>
        ),
      });
      setLoading({ ...loading, code: false });
      return;
    }

    // get me from api
    try {
      const meResponse = await getMe(signInResponse.user);
      message.success(`Sign in successful.  Welcome back ${meResponse.firstName}!`);
      setStep(0);
      setMe(meResponse);
      if (!isCart) { closeDialog!(); }
      setLoading({ ...loading, code: false });
    } catch (_) {
      message.success('Phone number confirmed.  Enter name and email to create account.');
      setStep(2);
      setLoading({ ...loading, code: false });
    }
  };

  const submitAccountInfo = async () => {
    try {
      await form.validateFields();
    } catch (validationError) {
      return;
    }

    setLoading({ ...loading, account: true });

    const firstName = form.getFieldValue('first-name');
    const lastName = form.getFieldValue('last-name');
    const email = form.getFieldValue('email');
    const user = firebase.auth().currentUser;
    let updatedMe;
    try {
      updatedMe = await createMe({
        firstName,
        lastName,
        email,
        phoneNumber: user?.phoneNumber,
      }, user);
    } catch (error) {
      message.error(`Error! ${error.message}`);
      setLoading({ ...loading, account: false });
      captureException(error);
      return;
    }

    setMe(updatedMe);
    message.success(`Account created. Welcome ${updatedMe.firstName}!`);
    setStep(0);
    if (!isCart) { closeDialog!(); }
    setLoading({ ...loading, account: false });
  };

  return (
    <>
      {
        isCart ? (
          <div>
            <>
              {
                queueSlots.length ? (
                  <>
                    <Divider style={{ margin: '0 0 1rem 0' }} />
                    <Descriptions
                      title="Pickup Time"
                      column={1}
                    />
                    <span style={{ color: '#d14242' }}>{pickupValidation}</span>
                  </>
                ) : null
              }
              <Form>
                {queueSlots.length ? (
                  <Form.Item>
                    <Radio.Group
                      onChange={(e) => {
                        setPickupTime!(e.target.value);
                      }}
                      defaultValue="a"
                      style={{ textAlign: 'center' }}
                    >
                      <Radio.Button
                        key={OrderType.ASAP}
                        value={OrderType.ASAP}
                        style={{ width: '33%', marginBottom: '12px' }}
                      >
                        ASAP
                      </Radio.Button>
                      {queueSlots.map((slot, index) => {
                        const start = moment(slot.utc_start);
                        const end = moment(slot.utc_end).format('h:mm');
                        const disableTime = start.clone().subtract(15, 'minutes');

                        return (
                          <Radio.Button
                            key={`${start.format('h:mm')} - ${end}`}
                            value={index}
                            style={{ width: '33%', marginBottom: '12px' }}
                            disabled={(slot.currentOrders === schedulePartner!.ordersPerQueueSlot)
                              || disableTime.isBefore()}
                          >
                            {`${start.format('h:mm')} - ${end}`}
                          </Radio.Button>
                        );
                      })}
                    </Radio.Group>
                  </Form.Item>
                ) : null}
                {
                  schedulePartner?.tag === 'movie' ? (
                    <>
                      <Divider style={{ margin: '0 0 1rem 0' }} />
                      <Descriptions
                        title="Deliver To Parking Space"
                        column={1}
                      />
                      {noteValidation && <div style={{ color: '#d14242', marginBottom: '20px' }}>{noteValidation}</div>}
                      <Form.Item>
                        <Input.Group compact>
                          <Select
                            defaultValue="Select"
                            style={{ width: '50%' }}
                            onSelect={(opt) => setNote!(1, opt)}
                          >
                            <Option value="" key="Select">Select</Option>
                            {
                              ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
                                .map((i) => <Option value={i} key={i}>{i}</Option>)
                            }
                          </Select>
                          <Select
                            defaultValue="Select"
                            style={{ width: '50%' }}
                            onSelect={(opt) => setNote!(2, opt)}
                          >
                            <Option value="" key="Select">Select</Option>
                            {
                              [
                                '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16',
                                '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31',
                                '32', '33', '34', '35', '36', '37',
                              ]
                                .map((i) => <Option value={i} key={i}>{i}</Option>)
                            }
                          </Select>
                        </Input.Group>
                      </Form.Item>
                    </>
                  ) : null
                }
              </Form>
            </>
            <Divider style={{ margin: '0 0 1rem 0' }} />
            <Descriptions
              title={steps[step] && steps[step].title === 'Confirm Payment' ? 'Payment' : 'Checkout'}
              column={1}
            />
          </div>
        ) : null
      }
      <Steps
        current={step}
        size="small"
        className="sign-in-steps"
      >
        {steps.map((item: any) => (
          <Step
            key={item.title}
            icon={item.icon}
          />
        ))}
      </Steps>
      <div
        className="steps-content"
        style={{ margin: steps[step] && steps[step].title === 'Confirm Payment' ? '0 0 1rem 0' : '1.5rem 0 0 0' }}
      >
        <Form
          form={form}
          hideRequiredMark
        >
          {steps[step] ? steps[step].content : null}
        </Form>
      </div>
    </>
  );
};

export default Checkout;
