/* eslint-disable no-new */
import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Button } from 'antd';
import { AimOutlined } from '@ant-design/icons';

import google from '../../common/google';

// eslint-disable-next-line no-unused-vars
import { Schedule, GeolocationPosition } from '../../common/types';

import { MapMarker } from './MapMarker';

class Map extends Component<{
  point: { lat: number, lng: number },
  radius: number,
  schedules: Schedule[],
  getSchedules: Function,
  setPoint: Function,
  scheduleHover: Function,
  scheduleClick: Function,
  hoveredScheduleId: string,
  clickedScheduleId: string,
  parentHandleApiLoaded: Function,
},
  {
    key: string,
    stateCircle?: any,
    stateMap?: any,
    searchQuery?: string,
  }> {
  constructor(props: any) {
    super(props);
    this.state = {
      key: 'AIzaSyB-aMBvgdoqh5cUfpuHkOFogrtehVVVQZw',
    };
  }

  render() {
    const {
      key,
      stateCircle,
      stateMap,
    } = this.state;
    const {
      point,
      radius,
      schedules,
      setPoint,
      scheduleHover,
      scheduleClick,
      hoveredScheduleId,
      clickedScheduleId,
      parentHandleApiLoaded,
      getSchedules,
    } = this.props;

    const updatePoint = (p: { lat: number, lng: number }) => {
      setPoint(p);
    };

    const handleApiLoaded = (_map, maps) => {
      this.setState({ stateMap: _map });
      const circle = new maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0,
        map: _map,
        draggable: false,
        center: point,
        radius: radius * 1500,
      });

      this.setState({ stateCircle: circle });

      const marker = new maps.Marker({
        position: point,
        animation: maps.Animation.DROP,
        draggable: true,
        map: _map,
      });

      marker.bindTo('position', circle, 'center');

      // debounce drag event
      let circleDragTimeout: any = null;
      circle.addListener('drag', () => {
        clearTimeout(circleDragTimeout);
        circleDragTimeout = setTimeout(() => {
          const position = marker.getPosition();
          const lat = position.lat();
          const lng = position.lng();

          updatePoint({ lat, lng });
          getSchedules({
            radius: circle.getRadius() / 1500,
            point: `${lat}, ${lng}`,
          });
        }, 300);
      });

      // debounce pan event
      let mapPanTimeout: any = null;
      _map.addListener('center_changed', () => {
        const center = _map.getCenter();
        const lat = center.lat();
        const lng = center.lng();
        circle.setCenter(new maps.LatLng(lat, lng));
        clearTimeout(mapPanTimeout);
        mapPanTimeout = setTimeout(() => {
          updatePoint({ lat, lng });
          getSchedules({
            radius: circle.getRadius() / 1500,
            point: `${lat}, ${lng}`,
          });
        }, 300);
      });

      parentHandleApiLoaded(_map, maps, circle);
    };

    const goToMyLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
          const location = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );
          stateMap.panTo(location);
          stateMap.setZoom(11);
          stateCircle.setCenter(location);
          const lat = location.lat();
          const lng = location.lng();
          setPoint({ lat, lng });
          getSchedules({
            radius: stateCircle.getRadius() / 1500,
            point: `${lat},${lng}`,
          });
        });
      }
    };

    return (
      (
        <div>
          <div className="map-container">
            <Button
              type="primary"
              className="my-location-button"
              shape="circle"
              icon={<AimOutlined />}
              size="large"
              onClick={() => goToMyLocation()}
            />
            <GoogleMapReact
              bootstrapURLKeys={{ key }}
              defaultCenter={{ lat: 38.9072, lng: -77.0369 }}
              defaultZoom={10}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              options={{
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false,
              }}
            >
              {
                schedules.map((schedule: Schedule) => (
                  <MapMarker
                    key={schedule.id}
                    lat={schedule.event.latitude}
                    lng={schedule.event.longitude}
                    schedule={schedule}
                    scheduleHover={scheduleHover}
                    scheduleClick={scheduleClick}
                    hoveredScheduleId={hoveredScheduleId}
                    clickedScheduleId={clickedScheduleId}
                  />
                ))
              }
            </GoogleMapReact>
          </div>
        </div>
      )
    );
  }
}

export default Map;
