import React, { useState, useContext, useMemo } from 'react';

import { Anchor, Divider, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import {
  // eslint-disable-next-line no-unused-vars
  CartContext, AuthContext, Schedule, SchedulePartner,
} from '../../common/types';
import { cartContext as _cartContext } from '../../contexts/cart/cartContext';
import { authContext as _authContext } from '../../contexts/auth/authContext';


import MenuItem from './MenuItem';

const { Link } = Anchor;
const { confirm } = Modal;

const Menu = (props: { menu: any; schedulePartner: SchedulePartner; schedule: Schedule }) => {
  const cartContext: CartContext = useContext(_cartContext);
  const authContext: AuthContext = useContext(_authContext);
  //   const getMe = authContext.getMe!;
  const setMe = authContext.setMe!;
  const {
    // auth,
    me,
  } = authContext;
  const addItem = cartContext.addItem!;
  const clearItems = cartContext.clearItems!;
  const setCartVisible = cartContext.setVisible!;
  const setCartSchedulePartner = cartContext.setSchedulePartner!;
  const setCartSchedule = cartContext.setSchedule!;
  const { schedulePartner, schedule } = props;
  const cartSchedulePartner = cartContext.schedulePartner;
  const [item, setItem] = useState<any>();
  const [itemVisible, setItemVisible] = useState(false);

  const menu = useMemo(() => ({
    ...props.menu,
    categories: props.menu.categories.map((category) => {
      const encodedName = category.name ? category.name
        .toLowerCase()
        .replace(/[^a-z0-9]/g, ' ')
        .trim()
        .replace(/ +/g, '-') : 'Misc';
      return {
        ...category,
        anchorHash: `category-${encodedName}`,
      };
    }),
  }), [props]);

  const continueAddItemToCart = async (val: any, price: number) => {
    setItem(null);
    const values = val;
    const { quantity } = values;
    delete values.quantity;

    Object.keys(values).forEach((key) => {
      if (values[key] && values[key].length) {
        values[key.split('#')[0]] = values[key];
      }
      delete values[key];
    });

    const variation = values.Variation[0];
    delete values.Variation;

    // if (!auth.isAnonymous
    //   && me.customers
    //   && me.customers
    //     .find((c) => parseInt(c.partnerId, 10) === parseInt(schedulePartner.id, 10))
    //   && !me.customer
    // ) {
    //   const newMe = await getMe({ xa: auth.token }, schedulePartner.id);
    //   setMe(newMe);
    // }

    const taxes = menu.taxes
      .find((tax) => (
        tax.id === schedulePartner.taxId
        // && tax.tax_data.inclusion_type === 'ADDITIVE'
      ));

    setCartSchedule(schedule);

    setCartSchedulePartner({
      ...schedulePartner,
      taxes,
    });

    addItem({
      id: item!.id, name: item!.name, quantity, price, modifiers: values, variation,
    });
    setItemVisible(false);

    setTimeout(() => setCartVisible(true), 500);
  };

  const onAddToCart = async (val: any, price: number) => {
    if (cartSchedulePartner
      && parseInt(cartSchedulePartner.id, 10) !== parseInt(schedulePartner.id, 10)) {
      confirm({
        title: 'Are you sure you want to add this item?',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            <div>This will remove cart items added at another vendor.</div>
            <div>Only one vendor may be ordered from at a time.</div>
          </div>
        ),
        okText: 'Yes',
        okType: 'default',
        cancelText: 'No',
        onOk() {
          if (me && me.customer) {
            const { customer, ...newMe } = me;
            setMe(newMe);
          }
          clearItems();

          continueAddItemToCart(val, price);
        },
      });
    } else {
      continueAddItemToCart(val, price);
    }
  };

  return (
    <div className="menu-container">
      <Anchor targetOffset={180}>
        {menu.categories.map((category) => (
          <Link
            key={category.id}
            href={`#${category.anchorHash}`}
            title={category.name || 'Misc'}
          />
        ))}
      </Anchor>
      <div className="menu-items">
        {
          menu.categories.map((category) => (
            <div key={category.id} id={category.anchorHash} className="menu-category">
              <h3>{category.name || 'Misc'}</h3>
              <Divider />
              <div>
                {
                  category.items.map((i) => (
                    <div
                      key={i.id}
                      className="menu-item"
                      onClick={() => {
                        setItem(i);

                        const scrollToTop = () => {
                          const c = document.documentElement.scrollTop || document.body.scrollTop;
                          if (c > 0) {
                            window.requestAnimationFrame(scrollToTop);
                            window.scrollTo(0, c - c / 8);
                          }
                        };
                        scrollToTop();

                        setItemVisible(true);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => { }}
                    >
                      {i.image_url
                        ? <img className="menu-item-image" src={i.image_url} alt="" />
                        : (
                          <div className="menu-item-image placeholder">
                            <span>{i.name.substr(0, 2)}</span>
                          </div>
                        )}
                      <div className="menu-item-info">
                        <h3 style={{ fontSize: i.name && i.name.length > 35 ? '13px' : 'initial' }}>{i.name}</h3>
                        <h4>{i.variations[0].item_variation_data.price_money && `$${(i.variations[0].item_variation_data.price_money.amount / 100).toFixed(2)}`}</h4>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
      {
        item ? (
          <MenuItem
            item={item}
            visible={itemVisible}
            onAddToCart={onAddToCart}
            schedulePartner={schedulePartner}
            onCancel={() => {
              setItem(null);
              setItemVisible(false);
            }}
          />
        )
          : null
      }
    </div>
  );
};

export default Menu;
