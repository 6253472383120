import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { buildingContext as BuildingContext } from './buildingContext';
import buildingReducer from './buildingReducer';
// eslint-disable-next-line no-unused-vars
import { BuildingRequestParams } from '../../common/types';
import {
  listBuildings, getBuildingById, addBuilding, removeBuilding,
} from '../../common/api-client';
import {
  GET_BUILDINGS_REQUEST,
  GET_BUILDINGS_SUCCESS,
  GET_BUILDINGS_ERROR,
  GET_BUILDING_REQUEST,
  GET_BUILDING_SUCCESS,
  GET_BUILDING_ERROR,
  CLEAR_BUILDING,
  SET_MY_BUILDINGS,
  ADD_TO_MY_BUILDINGS,
  REMOVE_FROM_MY_BUILDINGS,
  SET_POINT,
} from '../../common/dispatch';

const BuildingState = (props: { children: any }) => {
  const initialState: any = {
    buildings: [],
    myBuildings: [],
    point: { lat: 38.896060, lng: -77.227050 },
    isFetching: true,
  };
  const [state, dispatch] = useReducer(buildingReducer, initialState);

  const getBuildings = async (params: BuildingRequestParams) => {
    dispatch({ type: GET_BUILDINGS_REQUEST });
    try {
      const result = await listBuildings(params);
      // console.log(result);
      dispatch({
        type: GET_BUILDINGS_SUCCESS,
        payload: result,
      });
    } catch (err) {
      const m = err?.response?.data?.error;
      dispatch({
        type: GET_BUILDINGS_ERROR,
        payload: m,
      });
    }
  };

  const getBuilding = async (id: string) => {
    dispatch({ type: GET_BUILDING_REQUEST });
    try {
      const result = await getBuildingById(id);

      result.schedules = result.schedules
        .filter((sch) => sch.status !== 'canceled')
        .sort((a, b) => {
          const x: any = new Date(a.utc_start);
          const y: any = new Date(b.utc_start);
          return x - y;
        })
        .map((s) => ({
          ...s,
          food_trucks: s.food_trucks ? s.food_trucks.sort(() => Math.random() - 0.5) : [],
        }));
      dispatch({
        type: GET_BUILDING_SUCCESS,
        payload: result,
      });
    } catch (err) {
      const m = err?.response?.data?.error;
      dispatch({
        type: GET_BUILDING_ERROR,
        payload: m,
      });
    }
  };

  const getBuildingSchedule = async (buildingId: string, scheduleId: string) => {
    // const result = await getBuildingScheduleById(buildingId, scheduleId);

    const building = await getBuildingById(buildingId);

    building.schedules = building.schedules
      .filter((schedule) => parseInt(schedule.id, 10) === parseInt(scheduleId, 10));

    dispatch({
      type: GET_BUILDING_SUCCESS,
      payload: building,
    });
  };

  const setMyBuildings = (buildings) => {
    dispatch({
      type: SET_MY_BUILDINGS,
      payload: buildings.map((b) => b.building),
    });
  };
  const addToMyBuildings = async (building, me) => {
    if (!(state.myBuildings.indexOf(building) !== -1)) {
      try {
        await addBuilding(building.id, me);
        dispatch({
          type: ADD_TO_MY_BUILDINGS,
          payload: building,
        });
        message.success('Building added!');
      } catch (error) {
        message.error('Error adding building');
      }
    }
  };
  const removeFromMyBuildings = async (building) => {
    try {
      await removeBuilding(building.id);
      dispatch({
        type: REMOVE_FROM_MY_BUILDINGS,
        payload: building,
      });
      message.success('Building removed!');
    } catch (error) {
      message.error('Error removing building');
    }
  };
  const setPoint = (radius: number) => {
    dispatch({
      type: SET_POINT,
      payload: radius,
    });
  };

  const clearBuilding = async () => {
    dispatch({ type: CLEAR_BUILDING });
  };
  const { children } = props;

  return (
    <BuildingContext.Provider
      value={{
        getBuildings,
        buildings: state.buildings,
        getBuilding,
        getBuildingSchedule,
        building: state.building,
        clearBuilding,
        setMyBuildings,
        myBuildings: state.myBuildings,
        addToMyBuildings,
        removeFromMyBuildings,
        point: state.point,
        setPoint,
        isFetching: state.isFetching,
      }}
    >
      {children}
    </BuildingContext.Provider>
  );
};

export default BuildingState;

BuildingState.propTypes = {
  children: PropTypes.shape({}),
};

BuildingState.defaultProps = {
  children: {},
};
