import React, { useState } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Amplify from 'aws-amplify';
import * as firebase from 'firebase/app';
// import ScriptTag from 'react-script-tag';
import config from './config';

import Route from './components/_common/Route';

import Schedules from './components/Schedules/Schedules';
import Partner from './components/Partners/Partner';
import Nav from './components/_common/Nav';
import NotFound from './components/_common/NotFound';
import Buildings from './components/Building/Buildings';
import Building from './components/Building/Building';
import Orders from './components/Order/Orders';

import './App.scss';

import AuthState from './contexts/auth/AuthState';
import ScheduleState from './contexts/schedule/ScheduleState';
import PartnerState from './contexts/partner/PartnerState';
import CartState from './contexts/cart/CartState';
import BuildingState from './contexts/building/BuildingState';
import Cart from './components/Cart/Cart';

Amplify.configure(config.aws);

firebase.initializeApp(config.firebase);

function App() {
  const [cartKey, setCartKey] = useState(0);

  // Reset the cart by incrementing a key value
  // This is a workaround for the Square payment form build/destroy issues
  const resetCart = () => {
    setCartKey(cartKey + 1);
  };

  return (
    <AuthState>
      <BuildingState>
        <ScheduleState>
          <PartnerState>
            <CartState>
              <Router>
                <Nav />
                {/* <ScriptTag
                  isHydrating
                  type="text/javascript"
                  src={
                    process.env.REACT_APP_STAGE === 'prod'
                      ? 'https://js.squareup.com/v2/paymentform'
                      : 'https://js.squareupsandbox.com/v2/paymentform'
                  }
                /> */}
                <Switch>
                  <Route exact path="/" component={Schedules} />
                  <Route exact path="/partners/:partnerId" component={Partner} />
                  <Route exact path="/buildings" component={Buildings} />
                  <Route exact path="/buildings/:buildingId" component={Building} />
                  <Route exact path="/buildings/:buildingId/schedules/:scheduleId" component={Building} />
                  <Route exact path="/orders" component={Orders} />
                  <Route component={NotFound} />
                </Switch>
                <Cart key={cartKey} onReset={resetCart} />
              </Router>
            </CartState>
          </PartnerState>
        </ScheduleState>
      </BuildingState>
    </AuthState>
  );
}

export default App;
