import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Spin } from 'antd';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
import { ScheduleContext, Schedule, PartnerContext } from '../../common/types';
import { scheduleContext as _scheduleContext } from '../../contexts/schedule/scheduleContext';
import { partnerContext as _partnerContext } from '../../contexts/partner/partnerContext';

const { Panel } = Collapse;

// This is a temporary solution to filter out invalid data from the backend
// Defining the shape of the object as we expect it
/* eslint-disable camelcase */
interface UsableSchedule {
  id: number,
  name: string,
  time_start_format: string,
  time_end_format: string,
  time_start: string,
  time_end: string,
  food_trucks?: {}[],
  event: {
    location: string,
    building: number,
    buildingName: string,
  },
  utc_start: string;
  utc_end: string;
}
/* eslint-disable camelcase */

function isUsableSchedule(object: any): object is UsableSchedule {
  return typeof object.id === 'string'
    && typeof object.name === 'string'
    // && typeof object.time_start_format === 'string'
    // && typeof object.time_end_format === 'string'
    && (object.food_trucks ? object.food_trucks.constructor === Array : true)
    && typeof object.event === 'object'
    && typeof object.event.location === 'string'
    && typeof object.event.building === 'string';
}

function filterUnusableSchedules(schedules?: any[]): UsableSchedule[] {
  return schedules ? schedules.filter((schedule) => isUsableSchedule(schedule))
    .map((s) => ({ ...s, momentDate: moment(s.utc_start) }))
    .sort((a, b) => {
      if (a.momentDate.isBefore(b.momentDate)) {
        return -1;
      } if (a.momentDate.isSame(b.momentDate)) {
        return 0;
      }
      return 1;
    }) : [];
}

const List = (
  props: {
    scheduleHover: Function,
    hoveredScheduleId: string,
    scheduleClick: Function,
    selectedPartnerId?: string,
    setSelectedPartner?: Function,
    activeKey?: string,
  },
) => {
  const scheduleContext: ScheduleContext = useContext(_scheduleContext);
  const partnerContext: PartnerContext = useContext(_partnerContext);
  const { isFetching, errorMessage } = scheduleContext;
  const schedules = filterUnusableSchedules(scheduleContext.schedules);
  const clearPartner = partnerContext.clearPartner!;

  const { activeKey } = props;

  return (
    <>
      {isFetching ? <Spin style={{ margin: '50% 0 0 50%' }} /> : null}
      {
        // eslint-disable-next-line no-nested-ternary
        schedules!.length ? (
          <div>
            {errorMessage}
            <Collapse
              bordered={false}
              accordion
              defaultActiveKey={schedules[0].id}
              activeKey={activeKey
                || (schedules.find((s) => s.food_trucks && s.food_trucks.length) || {}).id}
            >
              {schedules.map((schedule) => (
                <Panel
                  showArrow={false}
                  disabled={!schedule.food_trucks || !schedule.food_trucks.length}
                  header={
                    (
                      <div
                        key={schedule.id}
                        className={`schedule-list-item ${props.hoveredScheduleId === schedule.id.toString() ? 'hover' : ''}`}
                        onMouseEnter={() => props.scheduleHover(schedule.id, true)}
                        onMouseLeave={() => props.scheduleHover(schedule.id, false)}
                        onClick={() => props.scheduleClick(schedule)}
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => { }}
                      >
                        <div className="popover-schedule-details">
                          <h3>{schedule.name}</h3>
                          {`${schedule.event.buildingName || (schedule.event.location ? schedule.event.location.substring(0, schedule.event.location.indexOf(',')) : '')}`}
                          <p>{`${moment(schedule.utc_start).format('h:mm a')} - ${moment(schedule.utc_end).format('h:mm a')}`}</p>
                          {schedule.food_trucks && schedule.food_trucks.length ? (
                            <div>
                              <img
                                className="partner-count-icon"
                                src={`${process.env.PUBLIC_URL}/assets/food-truck.svg`}
                                alt={`${schedule.food_trucks.length} partners`}
                              />
                              <div className="count">{schedule.food_trucks ? schedule.food_trucks.length : 0}</div>
                            </div>
                          ) : (null)}
                        </div>
                      </div>
                    )
                  }
                  key={schedule.id!}
                >
                  {
                    schedule.food_trucks && schedule.food_trucks.map((foodTruck: any) => (
                      <Link
                        key={foodTruck.id}
                        to={{
                          pathname: `buildings/${schedule.event.building}`,
                          search: `?schedule=${schedule.id}&partner=${foodTruck.id}`,
                        }}
                        onClick={() => {
                          clearPartner();
                          props.setSelectedPartner!(foodTruck.id);
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => { }}
                        className="schedule-list-partner"
                      >
                        <div className="schedule-list-partner-details">
                          <img src={foodTruck.photo_small || foodTruck.photo_thumbnail || foodTruck.photo} alt="img" />
                          <div>
                            {foodTruck.name}
                          </div>
                        </div>
                      </Link>
                    ))
                  }
                </Panel>
              ))}
            </Collapse>

          </div>
        ) : (isFetching ? null : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '10rem',
          }}
          >
            Nothing scheduled for this date
          </div>
        ))
      }
    </>
  );
};

export default List;
