/* eslint-disable no-unused-vars */
import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { captureException } from '@sentry/react';
import { cartContext as CartContext } from './cartContext';
import cartReducer, { CART_STORAGE_KEY } from './cartReducer';
import {
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  SET_CART_VISIBLE,
  CLEAR_CART_ITEMS,
  SET_CART_SCHEDULE_PARTNER,
  SET_CART_SCHEDULE,
  PLACE_ORDER,
  RENDER_PAYMENT_FORM,
  SET_PAYMENT_METHOD,
  RESET_CART,
  APPLY_PROMO,
} from '../../common/dispatch';
import { placeOrder, getCoupon } from '../../common/api-client';
import { Schedule } from '../../common/types';

const CartState = (props: { children: any }) => {
  const savedStateStr = window.localStorage.getItem(CART_STORAGE_KEY);
  const savedState = savedStateStr ? JSON.parse(savedStateStr) : null;
  const initialState: any = savedState || {
    cart: {
      items: [],
      total: 0,
      discount: 0,
    },
    visible: false,
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);

  const setVisible = async (action) => {
    dispatch({
      type: SET_CART_VISIBLE,
      payload: action,
    });
  };


  const setSchedulePartner = async (schedulePartner) => {
    dispatch({
      type: SET_CART_SCHEDULE_PARTNER,
      payload: schedulePartner,
    });
  };

  const setSchedule = async (schedule: Schedule) => {
    dispatch({
      type: SET_CART_SCHEDULE,
      payload: schedule,
    });
  };

  const addItem = async (item) => {
    dispatch({
      type: ADD_CART_ITEM,
      payload: item,
    });
  };

  const removeItem = async (item, index) => {
    dispatch({
      type: REMOVE_CART_ITEM,
      payload: { index, price: item.price },
    });
  };


  const clearItems = async () => {
    dispatch({
      type: CLEAR_CART_ITEMS,
    });
  };

  const submitOrder = async (order: any) => {
    try {
      const result = await placeOrder(order);
      dispatch({
        type: PLACE_ORDER,
        payload: result,
      });
      return true;
    } catch (error) {
      message.error('Error placing order!');
      captureException(error);
      return null;
    }
  };

  const applyPromoCode = async (coupon) => {
    let discount;
    if (coupon.couponType === 'PERCENTAGE') {
      const tax = state.cart.total * ((state.schedulePartner.taxes
        ? state.schedulePartner.taxes.tax_data.percentage : 0) / 100);
      discount = (parseFloat(state.cart.total) + tax) * (coupon.value / 100);
    } else {
      discount = coupon.value;
    }

    dispatch({
      type: APPLY_PROMO,
      payload: { discount, couponCode: coupon.code },
    });

    return discount;
  };

  const getPromoCode = async (code: string) => {
    const result = await getCoupon(code);
    return result;
  };

  const setRenderPaymentForm = async (value: boolean) => {
    dispatch({
      type: RENDER_PAYMENT_FORM,
      payload: value,
    });
  };

  const setPaymentMethod = async (method: string) => {
    dispatch({
      type: SET_PAYMENT_METHOD,
      payload: method,
    });
  };

  const resetCart = () => {
    dispatch({ type: RESET_CART });
  };

  const { children } = props;

  return (
    <CartContext.Provider
      value={{
        addItem,
        removeItem,
        clearItems,
        setVisible,
        setSchedulePartner,
        setSchedule,
        cart: state.cart,
        visible: state.visible,
        schedulePartner: state.schedulePartner,
        schedule: state.schedule,
        submitOrder,
        setRenderPaymentForm,
        renderPaymentForm: state.renderPaymentForm,
        setPaymentMethod,
        paymentMethod: state.paymentMethod,
        orderSummary: state.orderSummary,
        resetCart,
        getPromoCode,
        applyPromoCode,
        discount: state.discount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartState;

CartState.propTypes = {
  children: PropTypes.shape({}),
};

CartState.defaultProps = {
  children: {},
};
