import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge, Menu, Dropdown, Divider,
} from 'antd';
import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';

// eslint-disable-next-line no-unused-vars
import { CartContext, AuthContext } from '../../common/types';
import { cartContext as _cartContext } from '../../contexts/cart/cartContext';
import { authContext as _authContext } from '../../contexts/auth/authContext';

import SignIn from './SignIn';


const Nav = () => {
  const cartContext: CartContext = useContext(_cartContext);
  const authContext: AuthContext = useContext(_authContext);

  const [signInVisible, setSignInVisible] = useState<boolean>(false);

  const { visible, cart } = cartContext;
  const { auth, me } = authContext;

  const setVisible = cartContext.setVisible!;
  const signOutUser = authContext.signOutUser!;

  return (
    <div className="nav">
      <Link to="/">
        <img
          className="nav-logo"
          src={`${process.env.PUBLIC_URL}/assets/logo.png`}
          alt="logo"
        />
      </Link>

      <div style={{ display: 'flex' }}>
        <Dropdown
          overlay={(
            <Menu>
              <Menu.Item key="5">User</Menu.Item>
              <Menu.Divider />
              <Menu.Item key="0">{`firebase: ${auth?.phoneNumber || 'Anon'}`}</Menu.Item>
              <Menu.Item key="4">{`dynamo:  ${me && me.firstName ? me.firstName : 'None'}`}</Menu.Item>
              <Menu.Divider />
              {me ? (
                <Menu.Item key="1">
                  <Link to="/orders">
                    My Orders
                  </Link>
                </Menu.Item>
              ) : null}
              {me ? (
                <Menu.Item key="2">
                  <Link to="/buildings">
                    My Buildings
                  </Link>
                </Menu.Item>
              ) : null}
              {me ? (<Menu.Divider />) : null}
              <Menu.Item
                key="3"
                onClick={() => {
                  if (auth?.isAnonymous) {
                    setSignInVisible(true);
                  } else {
                    signOutUser();
                  }
                }}
              >
                {auth?.isAnonymous ? 'Sign In' : 'Sign Out'}
              </Menu.Item>
            </Menu>
          )}
          trigger={['click']}
        >
          <UserOutlined className="user-button" />
        </Dropdown>
        <Divider type="vertical" style={{ height: '33px' }} />
        <div
          className="cart-button"
          onClick={() => setVisible(!visible)}
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
        >
          <Badge count={cart.items.length}>
            <ShoppingCartOutlined
              style={{ fontSize: '30px' }}
            />
          </Badge>
        </div>
      </div>
      <SignIn visible={signInVisible} onCancel={() => setSignInVisible(false)} />
    </div>
  );
};

export default Nav;
