import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Modal, Form, Checkbox, Row, Col,
} from 'antd';
// eslint-disable-next-line no-unused-vars
import { SelectionType, SchedulePartner } from '../../common/types';

const MenuItem = ({
  visible,
  onAddToCart,
  onCancel,
  item,
  schedulePartner,
}: {
  visible: boolean,
  onAddToCart: Function,
  onCancel: any,
  item: any,
  schedulePartner: SchedulePartner,
}) => {
  const [form] = Form.useForm();
  const [price, setPrice] = useState<any>(0);


  function setValue(prev, cur) {
    let extras = 0;
    if (cur.length) {
      const formValue = {};
      cur.forEach((field) => {
        if (field.value && field.value.length) {
          const split = field.name[0].split('#');
          const modifierName: string = split[0];
          const selectionType: SelectionType = split[1];

          if (field.value[0].type !== 'ITEM_VARIATION') {
            field.value.forEach((v) => {
              if (v.price_money && v.price_money.amount) {
                extras += v.price_money.amount;
              }
            });
          }

          if (selectionType === SelectionType.SINGLE) {
            // set value to last clicked item
            formValue[`${modifierName}#${selectionType}`] = [field.value[field.value.length - 1]];
          }

          // TODO multiple selections issue
          // if (selectionType === SelectionType.MULTIPLE) {
          //   formValue[`${modifierName}#${selectionType}`] = field.value.slice(Math.max(
          //     field.value.length - maxNumberOfSelections, 0,
          //   ));
          // }
        }
      });
      form.setFieldsValue(formValue);
      const variation = form.getFieldValue('Variation#SINGLE');

      if (variation.length) {
        setPrice(variation[0].item_variation_data.price_money
          ? (((variation[0].item_variation_data.price_money.amount + extras) / 100).toFixed(2))
          : 0);
      }
    }
  }

  const menuItemMessage = (menuItem) => {
    if (typeof schedulePartner.partnerCheckOut === 'string') {
      return (
        <div className="menu-item-message">
          Ordering for this truck has been disabled
        </div>
      );
    }

    if (menuItem.isDisabled) {
      return (
        <div className="menu-item-message">
          Item is temporarily disabled
        </div>
      );
    }

    const start = schedulePartner.orderAheadDays
      ? moment(schedulePartner.utc_start).subtract(schedulePartner.orderAheadDays, 'days')
      : moment(schedulePartner.utc_start);

    const end = moment(schedulePartner.utc_end);

    if (start.isAfter()) {
      return (
        <div className="menu-item-message">
          Ordering opens at&nbsp;
          {start.format('h:mm a')}
        </div>
      );
    }
    if (end.isBefore()) {
      return (
        <div className="menu-item-message">
          Ordering closed at&nbsp;
          {end.format('h:mm a')}
        </div>
      );
    }
    return null;
  };

  const getOrderingDisabled = (menuItem) => {
    // if (process.env.REACT_APP_STAGE === 'prod' && schedulePartner.id !== '321') {
    if (schedulePartner.orderAheadDays) {
      if (moment(schedulePartner.utc_start).subtract(schedulePartner.orderAheadDays, 'days').isAfter()) {
        return true;
      }
    } else if (moment(schedulePartner.utc_start).isAfter()) {
      return true;
    }

    if (moment(schedulePartner.utc_end).isBefore()) {
      return true;
    }

    if (typeof schedulePartner.partnerCheckOut === 'string') {
      return true;
    }
    if (menuItem.isDisabled) {
      return true;
    }
    // }
    return false;
  };

  return (
    <Modal
      visible={visible}
      title={item?.name}
      okText={
        (
          <div>
            Add to Cart
            <div style={{ width: '50px', display: 'inline-block' }} />
            {`$${price || (item.variations[0].item_variation_data.price_money ? (item.variations[0].item_variation_data.price_money.amount / 100).toFixed(2) : 0)}`}
          </div>
        )
      }
      okButtonProps={{ disabled: getOrderingDisabled(item) }}
      cancelText="Close"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onAddToCart(
              values,
              price || (item.variations[0].item_variation_data.price_money.amount / 100).toFixed(2),
            );
            form.resetFields();
          })
          .catch(() => {
            // validation failed
          });
      }}
    >
      {item.description ? <div className="item-description">{item.description}</div> : null}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        onFieldsChange={setValue}
        initialValues={{
          quantity: 1,
          'Variation#SINGLE': [item.variations[0]],
        }}
      >
        <Form.Item
          name="Variation#SINGLE"
          rules={[{ required: true, message: 'Please select a variation' }]}
          style={{ display: item.variations.length === 1 ? 'none' : 'block' }}
        >
          <Checkbox.Group>
            <Row>
              {item.variations.map((v) => (
                <Col span={12} key={v.id}>
                  <Checkbox
                    value={v}
                    // style={{
                    //   lineHeight: '32px',
                    // }}
                    onClick={(e) => { e.preventDefault(); }}
                  >
                    {v.item_variation_data.name}
                    &emsp;
                    {
                      // eslint-disable-next-line no-nested-ternary
                      v.item_variation_data.price_money ? (v.item_variation_data.price_money.amount
                        > item.variations[0].item_variation_data.price_money.amount
                        ? `+ $${((v.item_variation_data.price_money.amount - item.variations[0].item_variation_data.price_money.amount) / 100).toFixed(2)}` : null
                      ) : null
                    }
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        {
          item.modifiers.map((modifier) => (
            <Form.Item
              key={modifier.id}
              name={`${modifier.name}#${modifier.selection_type}`}
              label={modifier.name}
              rules={[{
                required: modifier.min_selected_modifiers > 0,
                message: `Please select ${modifier.name}!`,
              }]}
            >
              <Checkbox.Group>
                <Row>
                  {modifier.modifiers.map((m) => (
                    <Col span={12} key={m.id}>
                      <Checkbox
                        value={m}
                      // style={{
                      //   lineHeight: '32px',
                      // }}
                      // onClick={(e) => { e.preventDefault(); }}
                      >
                        {m.name}
                        &emsp;
                        {m.price_money && m.price_money.amount ? `+ $${(m.price_money.amount / 100)}` : null}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          ))
        }
        {/* TODO implement item quantities */}
        {/* <Form.Item
          name="quantity"
          label="Quantity"
        >
          <InputNumber min={1} />
        </Form.Item> */}
      </Form>
      {menuItemMessage(item)}
    </Modal>
  );
};

export default MenuItem;

MenuItem.propTypes = {
  schedulePartner: PropTypes.shape({
    utc_start: PropTypes.string,
    utc_end: PropTypes.string,
    partnerCheckOut: PropTypes.string,
    orderAheadDays: PropTypes.number,
  }),
};


MenuItem.defaultProps = {
  schedulePartner: {
    utc_start: '',
    utc_end: '',
  },
};
