import React, {
  useEffect,
  useContext,
  Fragment,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  Input,
  Button,
  Tooltip,
} from 'antd';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  AimOutlined,
  UserOutlined,
} from '@ant-design/icons';
import google from '../../common/google';
// eslint-disable-next-line no-unused-vars
import { BuildingContext, GeolocationPosition, AuthContext } from '../../common/types';
import { buildingContext as _buildingContext } from '../../contexts/building/buildingContext';
import { authContext as _authContext } from '../../contexts/auth/authContext';

const Buildings = () => {
  const buildingContext: BuildingContext = useContext(_buildingContext);
  const authContext: AuthContext = useContext(_authContext);
  const getBuildings = buildingContext.getBuildings!;
  const addToMyBuildings = buildingContext.addToMyBuildings!;
  const setMyBuildings = buildingContext.setMyBuildings!;

  const removeFromMyBuildings = buildingContext.removeFromMyBuildings!;
  const {
    buildings, myBuildings, point,
  } = buildingContext;
  const { auth, me } = authContext;
  const [searchPoint, setSearchPoint] = useState(point);
  const [searchQuery, setSearchQuery] = useState<string>('');
  useEffect(() => {
    getBuildings(searchPoint);
  }, [searchPoint]);
  useEffect(() => {
    if (me) {
      setMyBuildings(me.buildings);
    }
  }, [me]);
  const addBuildingToUserList = (b) => {
    addToMyBuildings(b, me);
  };
  const removeBuildingFromUserList = (b) => {
    removeFromMyBuildings(b);
  };
  const autocomplete = new google.maps.places.Autocomplete(
    document.getElementById('autocomplete'),
    {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  );
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setSearchQuery(place.formatted_address);
      const { location } = place.geometry;
      const lat = location.lat();
      const lng = location.lng();
      setSearchPoint({ lat, lng });
    } else {
      const autocom: any = document.getElementById('autocomplete');
      autocom.placeholder = 'Enter a city';
    }
  });
  const setMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        const location = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude,
        );
        const lat = location.lat();
        const lng = location.lng();
        setSearchPoint({ lat, lng });
      });
    }
  };

  return (
    <div className="buildings-containter">
      <div id="search-controls--buildings">
        <Input.Group compact>
          <Input
            id="autocomplete"
            placeholder="Enter your location for buildings search"
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
          />
          <Tooltip title="Use Current Location">
            <Button
              type="primary"
              icon={<AimOutlined />}
              size="large"
              onClick={() => setMyLocation()}
            />
          </Tooltip>
        </Input.Group>

      </div>
      <h2 className="buildings-container--header">
        My Buildings
        {' '}
        {!!auth.isAnonymous
          && (
            <Button
              className="building-login"
              type="default"
              icon={<UserOutlined />}
              size="large"
              onClick={() => setMyLocation()}
            >
              Login To Save
            </Button>
          )}
      </h2>

      <div className="building-cards">
        {
          myBuildings!.map((b) => (
            <>
              <div className="building-card add-building">
                <Link
                  key={b.id}
                  to={`buildings/${b.id}`}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => { }}

                >
                  {b.name}
                </Link>
                <Tooltip title="Remove from My Buildings">
                  <MinusCircleOutlined className="add-building--icon" onClick={() => removeBuildingFromUserList(b)} />
                </Tooltip>
              </div>
            </>
          ))
        }
      </div>
      {myBuildings!.length ? <div style={{ padding: '0 1rem' }}><Divider /></div> : null}
      <h2 className="buildings-container--header">
        All Buildings
      </h2>
      <div className="building-cards">
        {
          buildings!.map((b) => (
            <Fragment key={b.id}>
              <div className="building-card add-building">
                <Link
                  key={b.id}
                  to={`buildings/${b.id}`}
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => { }}
                >
                  {b.name}
                </Link>
                <Tooltip title="Add to My Buildings">
                  <PlusCircleOutlined key={`${b.id}+circle`} className="add-building--icon" onClick={() => addBuildingToUserList(b)} />
                </Tooltip>
              </div>
            </Fragment>
          ))
        }
      </div>
    </div>
  );
};

export default Buildings;
