/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import Square from '../../common/square';
import config from '../../config';

const Payment = (
  {
    // buildPaymentForm,
    paymentFormRef,
    // nonceReceived,
    locationId,
  }: {
    buildPaymentForm: boolean;
    paymentFormRef: any;
    nonceReceived: Function;
    locationId?: string;
  },
) => {
  useEffect(() => {
    // TODO refactor conditional payment form render
    // using the `buildPaymentForm` trigger
    // when an existing customer clicks 'New Card'
    // if (buildPaymentForm) {
    const squarePaymentInit = async () => {
      const payments = Square.payments(config.square.applicationId, locationId);
      const card = await payments.card();
      await card.attach('#sq-card');
      paymentFormRef.current = card;
    };
    squarePaymentInit();
  }, []);

  return (
    <>
      <div className="payment-loading" id="sq-card" />
    </>
  );
};

export default Payment;
