import React, { useReducer } from 'react';
import * as firebase from 'firebase/app';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { authContext as AuthContext } from './authContext';
import authReducer from './authReducer';
import { getApiMe, updateApiMe } from '../../common/api-client';
import { SET_AUTH, SET_ME } from '../../common/dispatch';
// eslint-disable-next-line no-unused-vars
import { UserCreate } from '../../common/types';

const AuthState = (props: { children: any }) => {
  const initialState: any = {
    appLoading: true,
    authLoading: false,
    step: 0,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const getMe = async (user: FirebaseAuth, partnerId?: string) => getApiMe(user, partnerId);

  const initAuth = async () => {
    // firebase.auth().settings.appVerificationDisabledForTesting = true;
    try {
      firebase.auth().onAuthStateChanged(async (auth) => {
        if (auth) {
          dispatch({
            type: SET_AUTH,
            payload: auth,
          });

          const me = await getMe(auth);

          dispatch({
            type: SET_ME,
            payload: me,
          });
        } else {
          dispatch({
            type: SET_AUTH,
            payload: { isAnonymous: true },
          });

          // TODO get anonymous token
          // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
          //   .then(() => {
          //     firebase.auth().signInAnonymously().catch((error) => {
          //       message.error(`${error.code} Error ${error.message}`);
          //     });
          //   })
          //   .catch((error) => {
          //     message.error(`${error.code} Error ${error.message}`);
          //   });
        }
      });
    } catch (err) {
      message.error(err);
    }
  };

  const signInUser = (credential) => (
    new Promise((resolve, reject) => {
      // TODO convert anonymous user
      // if (state.auth.isAnonymous) {
      //   firebase.auth().currentUser!.linkWithCredential(credential)
      //     .then((auth) => {

      //       const { user } = auth;

      //       resolve(auth);
      //     }).catch((error) => {
      //       message.error(`${error.code} Error ${error.message}`);
      //     });
      // } else {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          firebase.auth().signInWithCredential(credential)
            .then((res) => {
              const auth = res;
              resolve(auth);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          message.error(`${err.code} Error ${err.message}`);
        });
      // }
    })
  );

  const setMe = (user) => {
    dispatch({
      type: SET_ME,
      payload: user,
    });
  };

  const signOutUser = () => {
    firebase.auth().signOut().then(() => {
      dispatch({
        type: SET_AUTH,
        payload: { isAnonymous: true },
      });
      dispatch({
        type: SET_ME,
        payload: null,
      });
      message.success('You\'ve been successfully signed out!');
    });
  };

  const setStep = (step) => {
    dispatch({
      type: 'SET_STEP',
      payload: step,
    });
  };

  interface FirebaseAuth {
    // TODO
  }

  const createMe = async (user: UserCreate) => updateApiMe(user);

  const { children } = props;

  return (
    <AuthContext.Provider
      value={{
        initAuth,
        signInUser,
        signOutUser,
        appLoading: state.appLoading,
        authLoading: state.authLoading,
        auth: state.auth,
        me: state.me,
        setMe,
        step: state.step,
        setStep,
        createMe,
        getMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;

AuthState.propTypes = {
  children: PropTypes.shape({}),
};

AuthState.defaultProps = {
  children: {},
};
