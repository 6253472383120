/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Loading from './Loading';

const RouteWithGuard: React.FC<any> = ({ component: Component, ...rest }) => {
  const authContext: AuthContext = useContext(_authContext);
  const {
    appLoading,
  } = authContext;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (appLoading) {
          return <Loading />;
        }
        // if (!isAuthenticated) {
        //   return <Redirect to="/" />;
        // }
        return <Component {...props} {...rest} />;
      }}
    />
  );
};

export default RouteWithGuard;
