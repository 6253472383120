import { SET_AUTH, SET_ME } from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case SET_AUTH:

      return {
        ...state,
        appLoading: false,
        authLoading: false,
        auth: {
          id: action.payload.uid,
          phoneNumber: action.payload.phoneNumber,
          token: action.payload.xa,
          isAnonymous: action.payload.isAnonymous,
        },
      };
    case SET_ME:
      return {
        ...state,
        me: action.payload,
      };
    case 'SET_STEP':
      return {
        ...state,
        step: action.payload,
      };
    default:
      return state;
  }
};
