/* eslint-disable arrow-body-style, react/jsx-one-expression-per-line, react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { Table, Tag, Spin } from 'antd';
import { listMeOrders } from '../../common/api-client';
import { authContext as _authContext } from '../../contexts/auth/authContext';
import Order from './Order';

// eslint-disable-next-line no-unused-vars
import { AuthContext } from '../../common/types';
// import { dummyPartnerOne, dummyPartnerTwo } from './dummyPartner';


const Orders = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { me } = authContext;
  const [orders, setOrders] = useState<any>();
  const [orderModalVisible, setOrderModalVisible] = useState(false);
  const [order, setOrderDetail] = useState({});
  const [loading, setLoading] = useState({});

  const getOrders = async () => {
    setLoading(true);
    const response = await listMeOrders();
    setOrders(response);
    setLoading(false);
  };

  const statusMap = {
    PROPOSED: 'Placed',
    RESERVED: 'In Progress',
    PREPARED: 'Prepared',
    COMPLETED: 'Completed',
  };

  let columns: any = [
    {
      title: 'Order',
      key: 'order',
      render: (o) => {
        return (
          <div>
            <div>{o.partnerName}</div>
            <div>{`#${o.orderNumFormatted}`}</div>
          </div>

        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      render: (o) => (o.refund ? 'Refunded' : statusMap[o.status]),
    },
  ];

  const isMobile = window.innerWidth < 767;

  if (!isMobile) {
    columns = [
      ...columns,
      {
        title: 'Pickup Time',
        dataIndex: 'pickupTime',
        key: 'createDate',
        render: (date) => {
          const d = new Date(date);
          return (
            <span>
              {`${d.toLocaleDateString('en-US', {
                day: 'numeric', month: 'numeric', year: '2-digit',
              })}\u00A0\u00A0\u00A0${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
            </span>
          );
        },
      },
      {
        title: 'Items',
        key: 'items',
        dataIndex: 'order',
        render: (o) => (
          <>
            {o.line_items.map((item) => (
              <Tag key={item.name}>
                {`${item.quantity} ${item.name} ${item.variation_name ? `(${item.variation_name})` : ''}`}
              </Tag>
            ))}
          </>
        ),
      },
      {
        title: 'Payment',
        dataIndex: 'payment',
        key: 'id',
        render: (payment) => (`$${payment.amount_money.amount / 100} from ${payment.card_details.card.card_brand} ending in ${payment.card_details.card.last_4}`),
      },
    ];
  }

  useEffect(() => {
    if (me) { getOrders(); }
    // eslint-disable-next-line
  }, [me]);
  const closeModal = () => {
    setOrderModalVisible(false);
  };
  const orderModalOn = (e) => {
    // const orderDetail = index === 0 ? { ...e.order, partner: dummyPartnerOne }
    //   : { ...e.order, partner: dummyPartnerTwo };
    setOrderDetail(e.order);
    setOrderModalVisible(true);
  };
  return (
    <>

      <div className="orders-container">
        <Order
          visible={orderModalVisible}
          onCancel={closeModal}
          order={order}
        />
        <Table
          onRow={(record) => {
            return {
              onClick: () => orderModalOn(record),
            };
          }}
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={orders && orders.sort((a, b) => {
            const x: any = new Date(a.createDate);
            const y: any = new Date(b.createDate);
            return y - x;
          })}
        >
          {loading && <Spin style={{ margin: '50% 0 0 50%' }} />}
        </Table>
      </div>
    </>
  );
};

export default Orders;
