/* eslint-disable import/prefer-default-export */
import React from 'react';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import MapPopover from './MapPopover';

// eslint-disable-next-line no-unused-vars
import { Schedule } from '../../common/types';

interface MapMarker {
  schedule: Schedule;
  lat: number;
  lng: number;
  scheduleHover: Function;
  hoveredScheduleId: string;
  scheduleClick: Function;
  clickedScheduleId: string;
}

export const MapMarker = ({
  schedule, scheduleHover, scheduleClick, clickedScheduleId, hoveredScheduleId,
  // eslint-disable-next-line arrow-body-style
}: MapMarker) => {
  return (
    <div>
      <Popover
        isOpen={clickedScheduleId === schedule.id}
        onClickOutside={(event: any) => {
          if (!event.path[0].innerText || event.path[0].innerText.length === 0) {
            scheduleClick('');
          }
        }}
        padding={10}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
            arrowStyle={{ marginBottom: '30px' }}
          >
            <span>{MapPopover(schedule)}</span>
          </ArrowContainer>
        )}
      >
        <span
          // @ts-ignore
          tooltip={schedule.name}
          onClick={() => {
            scheduleClick(schedule);
          }}
          role="button"
          tabIndex={0}
          onKeyPress={() => { }}
          className="schedule-marker"
          onMouseEnter={() => scheduleHover(schedule.id, true)}
          onMouseLeave={() => scheduleHover(schedule.id, false)}
        >
          <img
            className={`${hoveredScheduleId === schedule.id ? 'hover' : ''}`}
            src={`${process.env.PUBLIC_URL}/assets/food-truck.png`}
            alt={schedule.name}
          />
        </span>
      </Popover>
    </div>
  );
};
