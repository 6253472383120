import {
  GET_BUILDINGS_REQUEST,
  GET_BUILDINGS_SUCCESS,
  GET_BUILDINGS_ERROR,
  GET_BUILDING_REQUEST,
  GET_BUILDING_SUCCESS,
  GET_BUILDING_ERROR,
  CLEAR_BUILDING,
  SET_MY_BUILDINGS,
  ADD_TO_MY_BUILDINGS,
  REMOVE_FROM_MY_BUILDINGS,
  SET_POINT,
} from '../../common/dispatch';

export default (state: any, action: { type: string; payload?: any }) => {
  switch (action.type) {
    case GET_BUILDINGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      };
    case GET_BUILDINGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        buildings: action.payload,
      };
    case REMOVE_FROM_MY_BUILDINGS:
      return {
        ...state,
        myBuildings: state.myBuildings.filter((building) => building.id !== action.payload.id),
        buildings: [action.payload, ...state.buildings],
      };
    case SET_MY_BUILDINGS:
      return {
        ...state,
        myBuildings: action.payload,
      };
    case ADD_TO_MY_BUILDINGS:
      return {
        ...state,
        buildings: state.buildings.filter((building) => building.id !== action.payload.id),
        myBuildings: [...state.myBuildings, action.payload],
      };
    case GET_BUILDINGS_ERROR:
      return {
        ...state,
        isFetching: false,
        buildings: [],
        errorMessage: action.payload,
      };
    case GET_BUILDING_REQUEST:
      return {
        ...state,
        isFetching: true,
        errorMessage: '',
      };
    case GET_BUILDING_SUCCESS:
      return {
        ...state,
        building: action.payload,
        isFetching: false,
      };
    case GET_BUILDING_ERROR:
      return {
        ...state,
        isFetching: false,
        building: null,
        errorMessage: action.payload,
      };
    case SET_POINT:
      return {
        ...state,
        point: action.payload,
      };
    case CLEAR_BUILDING:
      return {
        ...state,
        building: null,
      };
    default:
      return state;
  }
};
