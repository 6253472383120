/* eslint-disable arrow-body-style, react/jsx-one-expression-per-line, react/prop-types */
import React from 'react';
import { Modal, Button } from 'antd';

interface IProps {
  order: any,
  visible,
  onCancel,
}

const Order: React.FC<IProps> = (props: any) => {
  const { order, visible, onCancel } = props;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  // const dateFormat = (isoTime: any) => {
  //   const returnDate = new Date(isoTime);
  //   return `${returnDate.toLocaleDateString('en-US', {
  //     day: 'numeric',
  //     month: 'short',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //   })}`;
  // };
  const orderItems = () => (
    order.line_items.map((item) => {
      return <li className="orders-item--item"><p>{item.quantity}</p><p> {item.name}</p><p className="orders-item--item-amount">{formatter.format(item.total_money.amount / 100)}</p></li>;
    })
  );
  const total = () => {
    const returnNumber = order.total_money.amount
      + order.total_tax_money.amount
      + order.total_service_charge_money.amount
      - order.total_discount_money.amount;
    return returnNumber / 100;
  };
  const getMoneyList = () => {
    return (
      <>
        <li className="orders-item--cost-item"><p className="label">Total</p><p>{formatter.format(total())}</p></li>

      </>
    );
  };
  const orderModalHeader = () => {
    const returnHeader = (
      <>
        <div><h2>{order.partnerName}</h2></div>
        {/* <div>Pick Up On {dateFormat(order.fulfillments[0].pickup_details.pickup_at)}</div> */}
        <div>{order.buildingName}</div>
        <div>{order.buildingPickupArea}</div>
      </>
    );
    return returnHeader;
  };
  return (
    <Modal
      title={props && order ? orderModalHeader() : ''}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={onCancel}
        >
          OK
        </Button>,
      ]}
    >
      {/* <h3>Order Number: {order.orderNumFormatted}</h3> */}
      <h3>Order Details:</h3>
      <ul className="orders-item-summary">{order && order.line_items && orderItems()}</ul>
      <ul className="orders-item-summary--cost">{order.total_money && getMoneyList()}</ul>
    </Modal>
  );
};

export default Order;
