import React from 'react';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
import { Schedule } from '../../common/types';

const MapPopover = (schedule: Schedule): any => (
  <div className="popover-content">
    <div className="popover-schedule-details">
      <h3>{schedule.name}</h3>
      <p>{schedule.event.location}</p>
      <p>{`${moment(schedule.utc_start).format('h:mm a')} - ${moment(schedule.utc_end).format('h:mm a')}`}</p>
    </div>
  </div>
);

export default MapPopover;
